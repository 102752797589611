import { ChangeEvent, useEffect, useState } from 'react';
import Wrapper from './customers_database_screen.styled';
import SearchBox from 'components/search_box';
import cn from 'classnames';
import { CustomerUserInfo } from 'silal_app_base_react/src/data/types/users';
import UsersRepository from 'data/repositories/users_repository';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { CustomerDatabaseCard } from '../users/components/user_cards';

function CustomersDatabase() {
  const [customers, setCustomers] = useState<CustomerUserInfo[]>([]);
  const [searchFocus, setSearchFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchCustomers = async () => {
      await UsersRepository.getAllCustomers().then((res) =>
        setCustomers(res ? res : []),
      );
      setLoading(false);
    };

    fetchCustomers();
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }
  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <div className="top-row">
        <h2 className="poppins-font">Customers database</h2>
        <div // <Icon
          className={cn('searchContainer', searchFocus && 'add-searchbutton')}
        >
          <SearchBox
            placeholder="Search"
            onFocus={() => setSearchFocus(true)}
            searchValue={searchValue}
            onChange={handleChange}
            className=""
            type="text"
          />
        </div>
      </div>
      <div className="grid-row lato-font">
        {customers
          .filter((customer) => {
            const sLower = searchValue.toLowerCase();
            return (
              searchValue === '' ||
              (customer?.first_name &&
                customer?.first_name.toLowerCase().includes(sLower)) ||
              (customer?.last_name &&
                customer?.last_name.toLowerCase().includes(sLower)) ||
              (customer.phone && customer?.phone.includes(sLower)) ||
              (customer.email && customer?.email.toLowerCase().includes(sLower))
            );
          })
          .map((customer) => CustomerDatabaseCard(customer))}
      </div>
    </Wrapper>
  );
}

export default CustomersDatabase;
