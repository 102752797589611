import { FilterBtnsStyled } from 'silal_app_base_react/src/pages/orders_archive/components/archived_orders_components.styled';
import TransactionsRepository from 'data/repositories/transactions_repository';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { PaymentTab } from '../payments_page';
import { MenuItem, Select } from '@mui/material';
import {
  Button,
  DateRangePicker,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  RangeValue,
  useDisclosure,
  ModalContent,
} from '@nextui-org/react';
import { DateValue } from '@internationalized/date';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { FaDownload } from 'react-icons/fa6';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import * as XLSX from 'xlsx';
import { Button as AntdButton } from 'antd';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';

enum UploadAction {
  NONE = 0,
  driversDas = 1,
  storesDas = 2,
  driversPayments = 3,
  storesPayments = 4,
}
interface FileData {
  [key: string]: any;
}

type ActionBtnProps = {
  currentDatabase: string;
  ids: number[];
  refreshData: () => void;
  dateRange: RangeValue<DateValue> | undefined;
  setDate: (value: RangeValue<DateValue>) => void;
  totalBalance: number;
  cashDebtToSilal?: number;
};
export const ActionBtn = ({
  currentDatabase,
  ids,
  refreshData,
  dateRange,
  setDate,
  totalBalance,
  cashDebtToSilal,
}: ActionBtnProps) => {
  const [dasFileDrivers, setDasFileDrivers] = useState(null);
  const [dasFileStores, setDasFileStores] = useState(null);

  const [paymentsFileDrivers, setPaymentsFileDrivers] = useState(null);
  const [paymentsFileStores, setPaymentsFileStores] = useState(null);

  const [action, setAction] = useState(UploadAction.NONE);

  const handleDASFileChangeDrivers = (e: any) => {
    setDasFileDrivers(e.target.files[0]);
  };
  const handleDASFileChangeStores = (e: any) => {
    setDasFileStores(e.target.files[0]);
  };

  const handlePaymentsFileChangeDrivers = (e: any) => {
    setPaymentsFileDrivers(e.target.files[0]);
  };
  const handlePaymentsFileChangeStores = (e: any) => {
    setPaymentsFileStores(e.target.files[0]);
  };
  return (
    <FilterBtnsStyled
      style={{
        // Csv Cross Modal is supported only for Stores
        gridTemplateColumns: `repeat(${
          currentDatabase === PaymentTab.STORES ? 5 : 4
        }, 1fr)`,
      }}
    >
      <PopupWithConfirmation
        open={action !== UploadAction.NONE}
        onButtonOneClick={() => {
          setAction(UploadAction.NONE);
        }}
        onButtonTwoClick={async () => {
          if (action === UploadAction.driversDas) {
            if (!dasFileDrivers) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', dasFileDrivers!);
            toast.warn('Please wait while we upload your file');
            const res =
              await TransactionsRepository.uploadDriverDasCSV(formData);
            if (!res) return;
            setDasFileDrivers(null);
            refreshData();
          } else if (action === UploadAction.storesDas) {
            if (!dasFileStores) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', dasFileStores);
            const res =
              await TransactionsRepository.uploadStoreDasCSV(formData);
            if (!res) return;
            setDasFileStores(null);
            refreshData();
          } else if (action === UploadAction.driversPayments) {
            if (!paymentsFileDrivers) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', paymentsFileDrivers!);
            const res =
              await TransactionsRepository.uploadDriverPaymentsCSV(formData);
            if (!res) return;
            setPaymentsFileDrivers(null);
            refreshData();
          } else if (action === UploadAction.storesPayments) {
            if (!paymentsFileStores) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', paymentsFileStores);
            const res =
              await TransactionsRepository.uploadStorePaymentsCSV(formData);
            if (!res) return;
            setPaymentsFileStores(null);
            refreshData();
          }
        }}
        headerTitle={`Upload the file as ${
          action === UploadAction.driversDas
            ? 'Drivers DAS'
            : action === UploadAction.storesDas
              ? 'Stores DAS'
              : action === UploadAction.driversPayments
                ? 'Drivers Payments'
                : 'Stores Payments'
        }?`}
        bodyText="Make sure you're the correct file to the correct database. Once you upload the file, the data will be added to the database and cannot be undone."
        buttonOneText="Cancel"
        buttonTwoText={`Upload`}
        withTimerOfNSeconds={10}
      />
      <DownloadExamples />
      <Button
        variant="flat"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        color="success"
        key="downloadCSV"
        onClick={async () => {
          if (ids.length === 0) {
            toast.warn('Please select at least one item');
            return;
          }
          if (currentDatabase === PaymentTab.STORES) {
            const res =
              await TransactionsRepository.getStoresPaymentReportsCSV(ids);
            if (!res) return;
          } else if (currentDatabase === PaymentTab.DRIVERS) {
            const res =
              await TransactionsRepository.getDriversPaymentReportsCSV(ids);
            if (!res) return;
          }
        }}
        endContent={<FaDownload />}
      >{`Selected ${
        currentDatabase === PaymentTab.DRIVERS ? 'Captains' : 'Stores'
      } (${ids.length})`}</Button>
      <Button
        variant="flat"
        endContent={<FaDownload />}
        color="success"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        onClick={async () => {
          await TransactionsRepository.getStoresPaymentReportsCSV([]);
        }}
      >
        All Stores Report
      </Button>
      <Button
        variant="flat"
        color="success"
        endContent={<FaDownload />}
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        onClick={async () => {
          await TransactionsRepository.getDriversPaymentReportsCSV([]);
        }}
      >
        All Captains Report
      </Button>{' '}
      {currentDatabase === PaymentTab.STORES && <CsvUploadModal />}
      {currentDatabase !== PaymentTab.STORES ? (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv,.xlsx"
            onChange={handleDASFileChangeDrivers}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: dasFileDrivers ? 'pointer' : 'not-allowed',
            }}
            variant="bordered"
            isDisabled={dasFileDrivers === null}
            onClick={async () => {
              setAction(UploadAction.driversDas);
            }}
          >
            Captains DAS +
          </Button>
        </div>
      ) : (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handleDASFileChangeStores}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: dasFileStores ? 'pointer' : 'not-allowed',
            }}
            // variant={dasFileStores !== null ? "solid" : "outline"}
            isDisabled={dasFileStores === null}
            variant="bordered"
            onClick={async () => {
              setAction(UploadAction.storesDas);
            }}
          >
            Stores DAS +
          </Button>
        </div>
      )}
      {currentDatabase !== PaymentTab.STORES ? (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handlePaymentsFileChangeDrivers}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: paymentsFileDrivers ? 'pointer' : 'not-allowed',
            }}
            variant="bordered"
            isDisabled={paymentsFileDrivers === null}
            onClick={async () => {
              setAction(UploadAction.driversPayments);
            }}
          >
            Captains Payments +
          </Button>
        </div>
      ) : (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handlePaymentsFileChangeStores}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: paymentsFileStores ? 'pointer' : 'not-allowed',
            }}
            variant="bordered"
            isDisabled={paymentsFileStores === null}
            onClick={async () => {
              setAction(UploadAction.storesPayments);
            }}
          >
            Stores Payments +
          </Button>
        </div>
      )}
      <div />
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-between">
          <div>
            Total Debt to {currentDatabase}:{' '}
            <span>{formatPrice(totalBalance)}</span>
          </div>
          {cashDebtToSilal && (
            <div>
              Cash Debt to Silal:{' '}
              <span>{formatPrice(cashDebtToSilal || 0)}</span>
            </div>
          )}
        </div>
        <DateRangePicker
          label="Orders Report Date Range"
          variant="faded"
          className="align-items-center"
          value={dateRange as any}
          onChange={setDate as any}
        />
      </div>
    </FilterBtnsStyled>
  );
};

export const DownloadExamples = () => {
  const [selectedExample, setSelectedExample] = useState('');

  const examples = [
    {
      label: 'Captains DAS CSV Example',
      headers: ['driver_id', 'das_percentage'],
      filename: 'drivers_das_example.csv',
    },
    {
      label: 'Stores DAS CSV Example',
      headers: ['store_id', 'das_percentage'],
      filename: 'stores_das_example.csv',
    },
    {
      label: 'Captains Payments CSV Example',
      headers: [
        'driver_id',
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ],
      filename: 'drivers_payments_example.csv',
    },
    {
      label: 'Stores Payments CSV Example',
      headers: [
        'store_id',
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ],
      filename: 'stores_payments_example.csv',
    },
  ];

  const downloadEmptyCsv = () => {
    const example = examples.find((item) => item.label === selectedExample);

    if (example) {
      const { headers, filename } = example;
      const emptyRow = headers.map(() => '').join(',');

      const csvContent = `data:text/csv;charset=utf-8,${headers.join(
        ',',
      )}\n${emptyRow}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', filename);
      document.body.appendChild(link);

      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Select
        value={selectedExample}
        onChange={(e) => setSelectedExample(e.target.value)}
        displayEmpty
        style={{
          marginRight: '10px',
          height: '40px',
          fontSize: '14px',
          minWidth: '220px',
          maxWidth: '220px',
        }}
        color="success"
      >
        <MenuItem value="" disabled>
          Select an empty example
        </MenuItem>
        {examples.map((example, index) => (
          <MenuItem key={index} value={example.label}>
            {example.label}
          </MenuItem>
        ))}
      </Select>

      <Button
        variant="flat"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        // onClick={downloadEmptyCsv}
        onClick={() => downloadEmptyCsv()}
        disabled={!selectedExample}
      >
        Download
      </Button>
    </div>
  );
};

const CsvUploadModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [originalRows, setOriginalRows] = useState<
    {
      key: string;
      store_id: string;
      tax_id: string;
      bank_number: number;
      bank_branch_number: number;
      bank_account_number: number;
      store_name: string;
      das_percentage: string;
      balance: string;
    }[]
  >([]);
  const [transactionsRows, setTransactionsRows] = useState<
    {
      key: string;
      transaction_date: string;
      transaction_id: string;
      paid_amount: string;
      bank_number: number;
      branch_number: number;
      account_number: number;
    }[]
  >([]);
  const handleFileUpload = (file: RcFile) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target?.result;
        if (arrayBuffer instanceof ArrayBuffer) {
          const binaryStr = new Uint8Array(arrayBuffer).reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');

          const workbook = XLSX.read(binaryStr, { type: 'binary' });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json: FileData[] = XLSX.utils.sheet_to_json(worksheet);
          const rows: any[] = [];
          json.forEach((item) => {
            rows.push({
              key: (Math.random() + 1).toString(36).substring(2),
              store_id: item['store_id'],
              tax_id: item['tax_id'],
              bank_number: item['bank_number'],
              bank_branch_number: item['bank_branch_number'],
              bank_account_number: item['bank_account_number'],
              store_name: item['store_name'],
              das_percentage: item['das_percentage'],
              balance: item['balance'],
            });
          });
          setOriginalRows(rows);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFile2Upload = (file: RcFile) => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const arrayBuffer = e.target?.result;
        if (arrayBuffer instanceof ArrayBuffer) {
          const binaryStr = new Uint8Array(arrayBuffer).reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');

          const workbook = XLSX.read(binaryStr, { type: 'binary' });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Convert worksheet to JSON while preserving headers for custom logic
          const sheetData: FileData[] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          // Dynamically find the header row
          const headerIndex = sheetData.findIndex((row) => {
            return (
              Array.isArray(row) &&
              row.includes('בחובה') &&
              row.includes('תאריך') &&
              row.includes('אסמכתא')
            );
          });

          if (headerIndex !== -1) {
            const dataRows = sheetData.slice(headerIndex + 1); // Slice rows starting from one after the header row

            const rows = dataRows.map((row) => {
              // Convert numeric dates to a readable format
              const rawDate = row[2];
              const transactionDate =
                typeof rawDate === 'number'
                  ? XLSX.SSF.format('yyyy-mm-dd', rawDate) // Convert Excel date number to ISO format
                  : rawDate;
              // Extract additional info from row[8] if available
              const additionalInfo = row[8];
              let bankDetails: {
                bank_number: number;
                branch_number: number;
                account_number: number;
              } = {
                bank_number: 0,
                branch_number: 0,
                account_number: 0,
              };
              if (typeof additionalInfo === 'string') {
                const match = additionalInfo.match(
                  /(\d{2})-(\d{3})-(\d{1,10})/,
                );
                if (match) {
                  bankDetails = {
                    bank_number: parseInt(match[1]),
                    branch_number: parseInt(match[2]),
                    account_number: parseInt(match[3]),
                  };
                }
              }

              return {
                key: (Math.random() + 1).toString(36).substring(2),
                transaction_date: transactionDate, // Date at index 2
                transaction_id: row[4], // ID at index 4
                paid_amount: row[5], // Amount at index 5
                bank_number: bankDetails.bank_number,
                branch_number: bankDetails.branch_number,
                account_number: bankDetails.account_number,
              };
            });

            // Filter out rows with missing any of the required fields
            const filteredRows = rows.filter((row) => {
              return (
                row.transaction_date &&
                row.transaction_id &&
                row.paid_amount &&
                row.bank_number &&
                row.branch_number &&
                row.account_number
              );
            });
            setTransactionsRows(filteredRows);
          } else {
            toast.warn('Could not find the header row with required fields.');
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  function crossCSVs() {
    // Match rows between originalRows and transactionsRows
    const crossedRows = originalRows.map((original) => {
      // Find the matching transaction row

      const matchingTransaction = transactionsRows.find(
        (transaction) =>
          transaction.bank_number === original.bank_number &&
          transaction.branch_number === original.bank_branch_number &&
          transaction.account_number === original.bank_account_number,
      );

      // Return the combined row
      return {
        store_id: original.store_id,
        tax_id: original.tax_id,
        bank_number: original.bank_number,
        bank_branch_number: original.bank_branch_number,
        bank_account_number: original.bank_account_number,
        store_name: original.store_name,
        das_percentage: original.das_percentage,
        balance: original.balance,
        paid_amount: matchingTransaction?.paid_amount || '',
        transaction_id: matchingTransaction?.transaction_id || '',
        transaction_date: matchingTransaction?.transaction_date || '',
      };
    });

    // Define headers for the new sheet
    const headers = [
      'store_id',
      'tax_id',
      'bank_number',
      'bank_branch_number',
      'bank_account_number',
      'store_name',
      'das_percentage',
      'balance',
      'paid_amount',
      'transaction_id',
      'transaction_date',
    ];

    // ? Export the data to a new Excel file, currently the upload supports only CSV
    // // Add headers to the sheet data
    // const sheetData = [
    //   headers,
    //   ...crossedRows.map((row) => Object.values(row)),
    // ];
    // // Create a new workbook and worksheet
    // const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Crossed Data');
    // // Export the workbook
    // XLSX.writeFile(workbook, 'Crossed_Data.xlsx');

    // Save file in CSV format
    const csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n${crossedRows
      // Backend expects the paid_amount to be positive
      .filter((row) => row.paid_amount && row.paid_amount !== '0')
      .map((row) =>
        headers.map((header) => row[header as keyof typeof row]).join(','),
      )
      .join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Crossed_Data.csv');
    document.body.appendChild(link);
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
    onClose();
  }

  return (
    <>
      <Button
        onPress={onOpen}
        color="success"
        variant="flat"
        className="mx-2 min-w-[230px]"
      >
        Cross CSV's
      </Button>
      <Modal
        title="Upload CSV Files"
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
      >
        <ModalContent>
          {(_) => (
            <>
              <ModalHeader className="flex-col">
                <h4>Cross Silal & Bank Transactions</h4>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  This tool is intended to fill Stores/Captains data with bank
                  transactions, you'll upload both files, then a new file will
                  be downloaded with Transaction Amount, Transaction ID already
                  filled.
                </p>
              </ModalHeader>
              <ModalBody>
                {' '}
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  <span
                    style={{
                      color: Colors.orangeBright,
                    }}
                  >
                    Important:
                  </span>{' '}
                  After downloading the new file, you need to upload it via the
                  "Stores/Captains Payments +" button as you do normally.
                </p>
                <p>1. Upload the empty Stores/Captains file you downloaded.</p>
                <Upload
                  listType="picture"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  maxCount={1}
                  beforeUpload={async (file) => {
                    handleFileUpload(file);
                    return false;
                  }}
                >
                  <AntdButton key={'file-upload'} icon={<UploadOutlined />}>
                    Stores/Captains File
                  </AntdButton>
                </Upload>
                <p>
                  2. Upload the transactions file you downloaded from the bank
                  website, make sure to include the columns: תיאור מורחב which
                  includes the name and bank account.
                </p>
                <Upload
                  listType="picture"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  maxCount={1}
                  beforeUpload={async (file) => {
                    handleFile2Upload(file);
                    return false;
                  }}
                >
                  <AntdButton key={'file-upload'} icon={<UploadOutlined />}>
                    Transactions File
                  </AntdButton>
                </Upload>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={onClose}
                  color="default"
                  style={{
                    fontSize: '14px',
                    height: '40px',
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={crossCSVs}
                  color="success"
                  isDisabled={
                    originalRows.length === 0 || transactionsRows.length === 0
                  }
                  style={{
                    fontSize: '14px',
                    height: '40px',
                  }}
                >
                  Cross CSV's
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CsvUploadModal;
