import { useState } from 'react';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Wrapper from './login_screen.styled';
import { silal_cover_big_image_placeholder } from 'assets/index';
import Spinner from 'silal_app_base_react/src/components/spinner';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { toast } from 'react-toastify';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormControl2 from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';
import { Input } from '@nextui-org/react';
import { BsEye, BsEyeSlash, BsEyeSlashFill } from 'react-icons/bs';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';

interface Data {
  email: string;
  password: string;
}

const enum LoginStep {
  EmailPass,
  SendOTP,
}

function LoginScreen() {
  const navigate = useNavigate();

  const [loginStep, setLoginStep] = useState(LoginStep.EmailPass);

  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [selectedVerificationMethod, setSelectedVerificationMethod] =
    useState('sms_otp');
  const [form1Data, setFrom1Data] = useState<Data>({
    email: '',
    password: '',
  });
  const [from2Data, setFrom2Data] = useState<{
    sms_otp: string;
    email_otp: string;
    temporar_token: string;
  }>({
    sms_otp: '+97254***1234',
    email_otp: 'example@silal.app',
    temporar_token: '',
  });

  const handleSubmitEmailPass = async () => {
    setSendingRequest(true);
    await AuthenticationRepository.loginEmailVerifyPassword(
      form1Data.email,
      form1Data.password,
    )
      .then((res) => {
        setSendingRequest(false);
        if (!res) {
          return;
        }
        setSendingRequest(false);
        setLoginStep(LoginStep.SendOTP);
        setFrom2Data(res as any);
      })
      .catch((err) => {
        setSendingRequest(false);
        toast.error(err);
      });
  };

  const sendLoginOtp = async () => {
    if (selectedVerificationMethod === 'sms_otp') {
      await AuthenticationRepository.loginSendMFAPhoneOTP(
        from2Data.temporar_token,
      )
        .then((res) => {
          setSendingRequest(false);
          if (!res) {
            return;
          }
          toast.success(res.msg);
          navigate('/phone-otp-verification', {
            state: {
              isPhone: true,
              info: from2Data.sms_otp,
              temporar_token: from2Data.temporar_token,
              previousPage: 'login',
              expiration_date: res.expiration_date,
            },
          });
        })
        .catch(() => {
          setSendingRequest(false);
        });
    } else {
      await AuthenticationRepository.loginSendMFAEmailOTP(
        from2Data.temporar_token,
      )
        .then((res) => {
          setSendingRequest(false);
          if (!res) return;
          toast.success(res.msg);
          navigate('/phone-otp-verification', {
            state: {
              isPhone: false,
              info: from2Data.email_otp,
              temporar_token: from2Data.temporar_token,
              previousPage: 'login',
              expiration_date: res.expiration_date,
            },
          });
        })
        .catch(() => {
          setSendingRequest(false);
        });
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100vh',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <img
        src={silal_cover_big_image_placeholder}
        style={{
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
          width: '100%',
          height: '100%',
          animation: 'slideBackground 120s',
        }}
      />
      <Wrapper
        style={{
          display: 'flex',
          top: '0%',
          left: '34%',
          height: '100%',
          width: '66%',
        }}
      >
        <div className="top-row">
          <div className="title">
            <h1>Silal Management Dashboard</h1>
            <h3>Log In</h3>
          </div>
          {loginStep === LoginStep.EmailPass ? (
            <>
              <div className="form">
                <div className="form-control-email row">
                  <div className="email col-12" style={{ margin: '5px' }}>
                    <Input
                      isRequired
                      label="Email"
                      variant="bordered"
                      color="success"
                      placeholder="email.example@gmail.com"
                      type="email"
                      value={form1Data.email}
                      onChange={(e) => {
                        setFrom1Data((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div className="password col-12" style={{ margin: '5px' }}>
                    <Input
                      isRequired
                      label="Password"
                      variant="bordered"
                      color="success"
                      placeholder="Enter password"
                      endContent={
                        isVisible ? (
                          <BsEyeSlash
                            style={{
                              cursor: 'pointer',
                              color: Colors.secondaryIcon,
                            }}
                            size={20}
                            onClick={() => setIsVisible(!isVisible)}
                          />
                        ) : (
                          <BsEye
                            style={{
                              cursor: 'pointer',
                              color: Colors.secondaryIcon,
                            }}
                            size={20}
                            onClick={() => setIsVisible(!isVisible)}
                          />
                        )
                      }
                      type={isVisible ? 'text' : 'password'}
                      value={form1Data.password}
                      onChange={(e) =>
                        setFrom1Data({
                          ...form1Data,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <FormControl2>
              <div
                style={{
                  padding: '30px',
                  borderRadius: '5px',
                }}
              >
                <FormLabel
                  color="success"
                  style={{
                    fontFamily: Fonts.secondary,
                  }}
                >
                  Verification Method
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="sms_otp"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="sms_otp"
                    control={<Radio color="success" />}
                    label={`SMS code to ${from2Data.sms_otp}`}
                    onChange={(e: any) =>
                      setSelectedVerificationMethod(e.target.value)
                    }
                  />
                  <FormControlLabel
                    value="email_otp"
                    control={<Radio color="success" />}
                    label={`Email code to ${from2Data.email_otp}`}
                    onChange={(e: any) =>
                      setSelectedVerificationMethod(e.target.value)
                    }
                  />
                </RadioGroup>
              </div>
            </FormControl2>
          )}
        </div>
        <div className="bottom-row row">
          <div className="form-submit">
            {sendingRequest ? (
              <Spinner />
            ) : (
              <Button
                onPress={() =>
                  loginStep === LoginStep.SendOTP
                    ? sendLoginOtp()
                    : handleSubmitEmailPass()
                }
                color="success"
                className="w-full"
              >
                {loginStep === LoginStep.SendOTP ? 'Send Code' : 'Log in'}
              </Button>
            )}
          </div>
        </div>
      </Wrapper>
      <style>
        {`
        @keyframes slideBackground {
        0% { transform: translateX(0); }
        100% { transform: translateX(-60%); }
        }
      `}
      </style>
    </div>
  );
}

export default LoginScreen;
