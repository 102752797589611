import Wrapper from './customer_profile_screen.styled';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { TextStyled, CardStyled } from '../../customers_database_screen.styled';
import { Icon } from '@iconify/react';
import { Link, useOutletContext } from 'react-router-dom';
import Star from 'assets/images/rating-star.svg';
import Walk from 'assets/images/walk.svg';
import Car from 'assets/images/car.svg';
import styledComponents from 'styled-components';
import AddressImg from 'assets/images/map_address_img.svg';
import EmailBox from 'assets/images/email_box.svg';
import CnicImage from 'assets/images/cnic_image.svg';
import PhoneBox from 'assets/images/phone_box.svg';
import MessageBox from 'assets/images/MessageBox.svg';
import PhoneIcon from 'assets/images/PhoneIcon.svg';
import { CustomerUserInfo } from 'silal_app_base_react/src/data/types/users';
import { Button } from '@nextui-org/react';
import { AbstractUserContactInformation } from 'pages/databases/users/screens/profile/user_profile_screen';
import { Address } from 'silal_app_base_react/src/data/types/address';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { EyeClosed, EyeOpen, EyeSlashed, LockOff, LockOn } from 'akar-icons';
import { FaLocationArrow, FaLocationPin, FaMapLocation } from 'react-icons/fa6';
import { TbCurrentLocation, TbLocationPin } from 'react-icons/tb';
import React, { useState } from 'react';

function CustomerProfile() {
  const customer = useOutletContext<CustomerUserInfo>();
  const animate = {
    y: [60, 0],
    opacity: [0, 1],
    transition: { duration: 0.5 },
  };

  return (
    <>
      <Wrapper>
        <div className="container grid-rows">
          <div className="row g-3 align-items-stretch">
            <div className="col-md-7 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <AbstractUserContactInformation user={customer} />
                </div>
              </div>
            </div>

            <div className="col-md-5 d-flex">
              <div className="card flex-fill">
                <div className="card-body">
                  <motion.div animate={animate}>
                    <CustomerInfo customer={customer} />
                  </motion.div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <motion.div animate={animate}>
                    <AddressesInformation
                      addressesList={customer?.addresses ?? []}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export function CustomerInfo({ customer }: { customer: CustomerUserInfo }) {
  const [showCartItems, setShowCartItems] = useState(false);
  const [showCreditCards, setShowCreditCards] = useState(false);

  return (
    <div className="profile-container lato-font">
      <h2 className="center mb-10">Customer Info</h2>

      <div className="col-12">
        <div className="row">
          <div className="col-md-6">
            <p>Birth Year</p>
            <h6>{customer.birth_year ?? 'Not provided'}</h6>
          </div>
          <div className="col-md-6">
            <p>Sex</p>
            <h6>
              {customer.is_male ? (
                <Icon
                  icon="material-symbols:male"
                  width="24"
                  height="24"
                  style={{ color: '#329903' }}
                />
              ) : (
                <Icon
                  icon="material-symbols:female"
                  width="24"
                  height="24"
                  style={{ color: '#f81261' }}
                />
              )}
            </h6>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-md-6">
            <p>Preferred Categories</p>
            <h6>
              {customer.preferred_categories?.map((item, index) => (
                <ul>
                  <li key={index} className="mb-3">
                    {item.name_en}
                  </li>
                </ul>
              ))}
            </h6>
          </div>
          <div className="col-md-6">
            <p>Watched Listings</p>
            <h6>{customer.watched_listings?.length || 'None'}</h6>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-md-6">
            <p>Credit Balance</p>
            <h6>{customer.credit_balance ?? 'Not available'}</h6>
          </div>
          <div className="col-md-6">
            <p>Orders</p>
            <h6>{customer.orders?.length ? customer.orders.length : 'None'}</h6>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="row">
          <div className="col-md-6">
            <p>
              Cart Items{' '}
              <button
                className="btn btn-sm"
                onClick={() => setShowCartItems((prev) => !prev)}
              >
                {showCartItems ? (
                  <EyeClosed color={Colors.secondaryIcon} size={20} />
                ) : (
                  <EyeOpen color={Colors.secondaryIcon} size={20} />
                )}
              </button>
            </p>
            {showCartItems && (
              <div>
                {customer?.cart_items?.length > 0 ? (
                  <ul>
                    {customer.cart_items.map((item, index) => (
                      <>
                        <li key={index} className="mb-3">
                          <h6>{index + 1})</h6>
                          <h6>
                            <strong>Name:</strong> {item.name_en}
                          </h6>
                          <h6>
                            <strong>Price:</strong> {item.price}
                          </h6>
                          <h6>
                            <strong>Quantity:</strong> {item.quantity}
                          </h6>
                          <h6>
                            <strong>Date Added:</strong>{' '}
                            {new Date(item.date_added).toLocaleDateString()}
                          </h6>
                        </li>
                        <hr></hr>
                      </>
                    ))}
                  </ul>
                ) : (
                  <h6>No items in the cart.</h6>
                )}
              </div>
            )}
          </div>

          {/* Credit Cards Section */}
          <div className="col-md-6">
            <p>
              Credit Cards{' '}
              <button
                className="btn btn-sm"
                onClick={() => setShowCreditCards((prev) => !prev)}
              >
                {showCreditCards ? (
                  <EyeClosed color={Colors.secondaryIcon} size={20} />
                ) : (
                  <EyeOpen color={Colors.secondaryIcon} size={20} />
                )}
              </button>
            </p>

            {showCreditCards && (
              <div>
                {customer?.credit_cards?.length > 0 ? (
                  <ul>
                    {customer.credit_cards.map((card, index) => (
                      <>
                        <li key={index} className="mb-3">
                          <h6>{index + 1})</h6>
                          <h6>
                            <strong>Card Brand:</strong> {card.card_brand}
                          </h6>
                          <h6>
                            <strong>Card Holder:</strong>{' '}
                            {card.card_holder_name}
                          </h6>
                          <h6>
                            <strong>Last 4 Digits:</strong>{' '}
                            {card.card_last_4_digits}
                          </h6>
                          <h6>
                            <strong>Expiry:</strong> {card.card_exp_month}/
                            {card.card_exp_year}
                          </h6>
                        </li>
                        <hr></hr>
                      </>
                    ))}
                  </ul>
                ) : (
                  <h6>No credit cards available.</h6>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function AddressesInformation({
  addressesList,
}: {
  addressesList: Address[];
}) {
  return (
    <div className="profile-container lato-font">
      <h2 className="center mb-10">Addresses</h2>
      {addressesList.map((address, index) => (
        <div key={index}>
          <p className="font-bold" style={{ fontSize: '25px' }}>
            {address.nick_name}
          </p>
          <h6>
            {address?.address_line_1}
            {address?.address_line_2 && `, ${address?.address_line_2}`}
            <br />
            {address?.town.name_en}, {address?.city.name_en}
            <br />
            <div className="flex justify-between">
              <span>
                <span
                  style={{
                    color: Colors.greenMain,
                  }}
                >
                  Notes:{' '}
                </span>
                {address?.notes ?? 'None'}
              </span>
              <div className="flex flex-row gap-2">
                {address?.locked ? (
                  <LockOn color={Colors.secondaryIcon} size={20} />
                ) : (
                  <LockOff color={Colors.secondaryIcon} size={20} />
                )}

                {address?.hidden ? (
                  <EyeClosed color={Colors.secondaryIcon} size={20} />
                ) : (
                  <EyeOpen color={Colors.secondaryIcon} size={20} />
                )}
                <TbCurrentLocation
                  color={Colors.greenAlternate}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    // Open google maps with the address
                    window.open(
                      `https://www.google.com/maps/search/?api=1&query=${address.lat},${address.lng}`,
                      '_blank',
                    );
                  }}
                  size={20}
                />
              </div>
            </div>
          </h6>
        </div>
      ))}
    </div>
  );
}
export default CustomerProfile;
