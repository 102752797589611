import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { AxiosInstance } from 'axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { getCurrentEnviromentType } from 'silal_app_base_react/src/utils/axios/configutation';
import { ENVIROMENT_TYPE } from 'silal_app_base_react/src/config/constants';
import { toast } from 'react-toastify';

class StoresApiRepository extends AuthenticatedApiRepository {
  private static instance: StoresApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: AxiosInstance): StoresApiRepository {
    if (!StoresApiRepository.instance) {
      StoresApiRepository.instance = new StoresApiRepository(axiosInstance);
    }
    return StoresApiRepository.instance;
  }

  getStoreDetails = async (store_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/stores/${store_id}/details/`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.store as StoreDetails;
    } catch (e: any) {
      return false;
    }
  };

  storeCredentials = async (store_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/generate_access_account_creds/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return {
        email: res.data.email,
        password: res.data.password,
      };
    } catch (e: any) {
      return false;
    }
  };

  getStoreOrdersReport = async (
    store_id: number,
    {
      start_date,
      end_date,
      format = 'html', // default to HTML if not specified
      downloadedFilename,
    }: {
      start_date?: string;
      end_date?: string;
      format: 'html' | 'pdf';
      downloadedFilename?: string;
    },
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/documents/reports/get_store_report/${store_id}/${
            start_date && end_date
              ? `?start_date=${start_date}&end_date=${end_date}`
              : ``
          }`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      if (format === 'html') {
        // download HTML file
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          downloadedFilename ?? `store_report_${store_id}.html`,
        );
        document.body.appendChild(link);
        link.click();
        // remove link
        document.body.removeChild(link);
      } else if (format === 'pdf') {
        const options = {
          method: 'POST',
          url: 'https://api.pdfendpoint.com/v1/convert',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${
              (import.meta as any).env.VITE_PDFENDPOINT_KEY
            }`,
          },
          data: JSON.stringify({
            sandbox: getCurrentEnviromentType() === ENVIROMENT_TYPE.DEVELOPMENT,
            orientation: 'vertical',
            page_size: 'A4',
            margin_top: '2cm',
            margin_bottom: '2cm',
            margin_left: '2cm',
            margin_right: '2cm',
            html: res.data,
            disable_html_validation: true,
          }),
        };
        toast.info('Generating PDF report, please wait...');
        await this.axiosInstance
          .request(options)
          .then(async function (response) {
            console.log(response);
            await fetch(response.data.data.url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/pdf',
              },
            })
              .then((r) => r.blob())
              .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                  'download',
                  downloadedFilename ?? `store_report_${store_id}.pdf`,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode?.removeChild(link);
              });
          });
      }

      return true;
    } catch (e: any) {
      return false;
    }
  };
}

const StoresRepository = StoresApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);
export default StoresRepository;
