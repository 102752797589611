import { NavLink } from 'react-router-dom';
import Wrapper from '../../customers/components/top_row.styled';
import { AbstractUserInfo } from 'silal_app_base_react/src/data/types/users';

function UserProfileToprow({ user }: { user: AbstractUserInfo }) {
  const UserProfileNavData = [
    {
      id: 'f5343',
      name: 'Profile',
      pathName: 'profile',
    },
  ];
  return (
    <Wrapper>
      <div>
        <div className="row">
          <div className="top-row">
            <div className="customer-name-id poppins-font">
              <h2>{user.first_name + ' ' + user.last_name}</h2>
            </div>
          </div>
          <div className="top-row-buttons poppins-font">
            {UserProfileNavData.map((nav) => (
              <NavLink
                key={nav.id}
                to={nav.pathName}
                className={(info) => (info.isActive ? 'active' : '')}
                end
              >
                {nav.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default UserProfileToprow;
