import { Modal } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Heading from 'components/headings/main_heading';
import RightArrow from 'assets/images/right-arrow.svg';
import Wrapper from './settings_page.styled';
import StoresRepository from 'data/repositories/stores_repository';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { toast } from 'react-toastify';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import {
  actionSetLastFetchedSilalImages,
  actionSetLastFetchedStoreImages,
} from 'silal_app_base_react/src/store/images/image_library_actions';
import { actionSetLastFetchedOrders } from 'store/orders/orders_actions';
import { actionSetLastFetchedRequests } from 'store/requests/requests_actions';
import { actionSetLastFetchedTemplates } from 'store/templates/templates_actions';
import { useDispatch } from 'react-redux';

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [constants_modal, setConstantsModal] = useState(false);
  const [confirm_modal, setConfirmModal] = useState(false);
  return (
    <>
      <Wrapper>
        <PopupWithConfirmation
          open={confirm_modal}
          headerTitle={'Revoke Access'}
          bodyText={'Are you sure you want to revoke access?'}
          buttonTwoText="Yes"
          withTimerOfNSeconds={5}
          onButtonTwoClick={async () => {
            const stores = (await SolrRepository.getStores()) ?? [];
            if (!stores) return;
            for (let i = 0; i < stores.length; i++) {
              await StoresRepository.storeCredentials(stores[i].id).then(
                (res) => {
                  if (!res)
                    toast.error(
                      `Failed to revoke access for ${stores[i].name_en}`,
                    );
                },
              );
            }
          }}
          onButtonOneClick={() => setConfirmModal(false)}
        />

        <div className="main_section">
          <Heading text={'Settings'} />

          <div className="body">
            <div className="options">
              <div className="category-col">
                <div // <Icon
                  className={`item`}
                  onClick={() => setConstantsModal(true)}
                >
                  Constants
                  <img src={RightArrow} alt="right_icon" />
                </div>
              </div>
              <div className="category-col">
                <div // <Icon
                  className={`item`}
                  onClick={() => navigate('/logistics')}
                >
                  Routing Schedule
                  <img src={RightArrow} alt="right_icon" />
                </div>
              </div>
              <div className="category-col">
                <div // <Icon
                  className={`item`}
                  onClick={() => navigate('workforce')}
                >
                  Workforce Management
                  <img src={RightArrow} alt="right_icon" />
                </div>
              </div>
              <div className="category-col">
                <div // <Icon
                  className={`item`}
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    toast.warn('Clearing Caches...');
                    dispatch(actionSetLastFetchedSilalImages(0));
                    dispatch(actionSetLastFetchedStoreImages(0));
                    dispatch(actionSetLastFetchedOrders(0));
                    dispatch(actionSetLastFetchedRequests(0));
                    dispatch(actionSetLastFetchedTemplates(0));
                    // Wait 3 seconds then toast
                    setTimeout(() => {
                      toast.success('Caches Cleared');
                    }, 3000);
                  }}
                >
                  Clear Caches
                </div>
              </div>
              <div className="category-col">
                <div // <Icon
                  className={`item`}
                  style={{
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={async () => {
                    setConfirmModal(true);
                  }}
                >
                  Revoke All Stores Access
                </div>
              </div>
            </div>
            {constants_modal && (
              <div className="constants">
                <Heading text={'Constants'} style={{ marginRight: '20px' }} />

                <div className="bg-white">
                  <div className="w-60">
                    <FormControl
                      input={true}
                      placeholder={'Constant Value'}
                      labelValue={'Constant Name'}
                    />
                    <FormControl
                      input={true}
                      placeholder={'Constant Value'}
                      labelValue={'Constant Name'}
                    />
                    <FormControl
                      input={true}
                      placeholder={'Constant Value'}
                      labelValue={'Constant Name'}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default Settings;
