import { Link } from 'react-router-dom';
import Wrapper from './databases_page.styled';

export default function DatabasesPage() {
  return (
    <Wrapper>
      <div className="header">
        <h4>Databases</h4>
      </div>
      <div className="cards">
        <div className="card">
          <Link to="/databases/users">
            <h6>Users</h6>
          </Link>
        </div>
        <div className="card">
          <Link to="/databases/customers">
            <h6>Customers</h6>
          </Link>
        </div>

        <div className="card">
          <Link to="/databases/stores">
            <h6>Stores</h6>
          </Link>
        </div>
        <div className="card">
          <Link to="/databases/drivers">
            <h6>Captains</h6>
          </Link>
        </div>

        <div className="card">
          <Link to="/databases/orders-archive">
            <h6>Orders</h6>
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}
