import styled from 'styled-components';

const Wrapper = styled.div`
  .grid-rows {
    .card {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .profile-container {
      background: var(--color-light);
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
      .avatar {
        width: 125px;
        height: 125px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
      }
      .time-details {
        background: rgba(90, 179, 168, 0.15);
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 15px;
        h5 {
          font-weight: 600;
          font-size: 12px;
          line-height: 120%;
          text-transform: uppercase;
          margin-bottom: 10px;
          color: var(--app-color-dark);
        }
        p {
          margin-bottom: 3px;
        }
        h6 {
          margin: 0;
        }
      }
      h6 {
        font-weight: bold;
        font-size: 15px;
        line-height: 120%;
        letter-spacing: -0.408px;
        color: var(--color-dark);
        margin-bottom: 10px;
      }
      p {
        font-weight: 600;
        font-size: 13px;
        line-height: 120%;
        color: rgba(76, 104, 112, 0.8);
        margin-bottom: 6px;
      }
      .footer {
        margin: 0 -20px;
        border-top: 1px solid #ebeeef;
        padding: 20px;
      }
    }
    .d-flex {
      gap: 20px;
    }
    .details-card-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      .size-15 {
        font-size: 15px;
      }
    }
    .methods-container {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr 4fr;
      gap: 10px;
      .method {
        padding: 12px;
        height: 42px;
        background: #f1f2f3;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 15px;
        line-height: 120%;
        color: #4c6870;
        svg {
          margin-right: 6px;
        }
      }
    }
    .two-grid {
      display: flex;
      justify-content: end;
      a {
        margin: 0;
        padding: 0;
      }
      // display: grid;
      // grid-template-columns: 1fr 1fr;
      // gap: 20px;
    }
  }

  .modal-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(4, 26, 33, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-box {
      padding: 20px;
      border-radius: 5px;
      max-width: 546px;
      width: 100%;
      background: var(--color-light);
      .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
        h3 {
          font-size: 18px;
          font-weight: 700;
          color: var(--color-dark);
          width: 100%;
          text-align: center;
        }
        button {
          border: none;
          background: transparent;
          font-size: 25px;
          cursor: pointer;
        }
      }
      .body {
        margin-bottom: 80px;
        p {
          margin-bottom: 20px;
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          color: #4c6870;
        }
        .form-control {
          text-align: center;
          input {
            width: 45px;
            height: 55px;
            border: 1px solid #cdcdd0;
            border-radius: 5px;
            text-align: center;
            font-size: 18px;
            padding: 10px;
            outline: none;
            :focus {
              border: 1px solid #36b27d;
            }
            :not(:first-child) {
              margin-left: 10px;
            }
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
      .footer {
        text-align: center;
        button {
          max-width: 329px;
          width: 100%;
          border: 1px solid transparent;
          transition: 0.3s ease;
          :hover {
            background: transparent;
            border: 1px solid var(--app-color-dark);
            color: var(--app-color-dark);
          }
        }
      }
    }
  }
`;

export default Wrapper;
