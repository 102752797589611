import { useEffect, useState } from 'react';
import Wrapper from './otp_screen.styled';
import Otpcode from '../../components/otp_code';
import BackButton from 'components/back_button';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionLoginMFAVerifyOTP } from 'store/authentication/authentication_actions';
import Spinner from 'silal_app_base_react/src/components/spinner';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { toast } from 'react-toastify';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  EMAIL_OTP_CODE_LENGTH,
  PHONE_OTP_CODE_LENGTH,
} from 'silal_app_base_react/src/config/constants';
import { Button, InputOtp } from '@nextui-org/react';

function OtpScreen() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [sendingRequest, setSendingRequest] = useState(false);
  const [otp, setotp] = useState('');

  const { initialMinute = 0, initialSeconds = 0 } = {};
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    // Auto Submit when all input fille
    if (sendingRequest) return;
    if (!location.state.isPhone && otp.length === EMAIL_OTP_CODE_LENGTH) {
      handleSubmitPhoneOtp();
    }
    if (location.state.isPhone && otp.length === PHONE_OTP_CODE_LENGTH) {
      handleSubmitPhoneOtp();
    }
  }, [otp]);

  // otp resend
  async function resendCode(e: any) {
    // TODO fix type
    if (e.target.textContent === 'Resend code') {
      setMinutes(3);
      if (location.state.isPhone) {
        await AuthenticationRepository.loginSendMFAPhoneOTP(
          location.state.temporar_token,
        )
          .then((res) => {
            toast.success(res.msg);
          })
          .catch(() => {
            setSendingRequest(false);
          });
      } else {
        await AuthenticationRepository.loginSendMFAEmailOTP(
          location.state.temporar_token,
        )
          .then((res) => {
            toast.success(res.msg);
          })
          .catch(() => {
            setSendingRequest(false);
          });
      }
    } else {
      return false;
    }
  }

  const handleSubmitPhoneOtp = () => {
    setSendingRequest(true);
    if (location.state.isPhone) {
      if (otp.length < PHONE_OTP_CODE_LENGTH) {
        toast.warn('Please enter the correct OTP');
        setSendingRequest(false);
        return false;
      }
    } else {
      if (otp.length < EMAIL_OTP_CODE_LENGTH) {
        toast.warn('Please enter the correct OTP');
        setSendingRequest(false);
        return false;
      }
    }
    const payload = {
      data: {
        isPhone: location.state.isPhone,
        temporar_token: location.state.temporar_token,
        otp: otp,
      },
      success: () => {
        setSendingRequest(false);
        window.location.replace('/dashboard');
      },
      error: () => {
        setSendingRequest(false);
      },
    };
    dispatch(actionLoginMFAVerifyOTP(payload));
  };

  return (
    <Wrapper>
      <div className="go-back-button">
        <BackButton primaryIcon={true} />
      </div>
      <div className="top-row">
        <div className="title">
          <p>OTP</p>
        </div>
        <div className="otp-code">
          <h2 className="poppins-font">OTP code</h2>
          <p className="lato-font">We have sent the code verification to</p>
          <p className="lato-font mb-0 pb-0">
            <strong>{location.state.info}</strong>
          </p>
          <br />
        </div>
        {/* <Otpcode num={num} onChange={handleChange} /> */}
        <InputOtp
          isRequired
          size="lg"
          variant="bordered"
          className="m-auto max-w-80"
          color="success"
          length={
            location.state.isPhone
              ? PHONE_OTP_CODE_LENGTH
              : EMAIL_OTP_CODE_LENGTH
          }
          value={otp}
          allowedKeys={
            '^\\d+$' // only allow numbers
          }
          onValueChange={(e) => {
            setotp(e);
          }}
        />
        <div
          className="resend-otp"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={() => {
              setotp('');
            }}
            style={{
              color: Colors.greyStrong,
              fontStyle: 'normal',
              borderBottom: 'none',
            }}
          >
            {`Clear`}
          </button>

          <button
            onClick={resendCode}
            style={{
              cursor:
                minutes === 0 && seconds === 0 ? 'pointer' : 'not-allowed',
            }}
          >
            {minutes === 0 && seconds === 0
              ? 'Resend code'
              : `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}
                `}
          </button>
        </div>
      </div>
      <div className="bottom-row">
        <div className="form-submit">
          {sendingRequest ? (
            <Spinner />
          ) : (
            <Button
              className="w-52"
              color="success"
              onClick={() => handleSubmitPhoneOtp()}
            >
              Verify OTP
            </Button>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default OtpScreen;
