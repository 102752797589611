import { Fonts } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  .top-row {
    margin-bottom: 23px;
    h2 {
      font-size: 25px;
      font-weight: 700;
      color: var(--color-dark);
    }
  }
  .searchContainer {
    margin-top: 23px;
    margin-bottom: 22px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    column-gap: 10px;
    button {
      border: none;
      background: var(--color-light);
      font-size: 15px;
      font-weight: 500;
      border: 1px solid rgba(232, 232, 232, 0.5);
      border-radius: 5px;
      color: #4c6870;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.3s ease;
      :hover {
        background: var(--app-color-dark);
        color: var(--color-light);
      }
      svg {
        margin-right: 5px;
        width: 24px;
        height: 24px;
      }
    }
    .search {
      background: var(--app-color-dark);
      color: var(--color-light);
      font-size: 15px;
      font-weight: 600;
      :hover {
        color: var(--app-color-dark);
        background: var(--color-light);
        border: 1px solid var(--app-color-dark);
      }
    }
    .sort-button {
      width: 44px;
      margin-right: 10px;
    }
    .filter {
      flex: 1;
    }
  }
  .add-searchbutton {
    grid-template-columns: 4fr 1fr 1fr;
  }
  .filter-options {
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
    .select-container {
      p {
        margin-bottom: 5px;
      }
      .select {
        .value-container {
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
          color: #ccd4d6;
          padding: 5px 10px;
          padding-top: 7px;
        }
        .dropdown {
          z-index: 99;
          padding: 0;
          button {
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 10px;
            font-family: 'Lato', sans-serif;
          }
          .selected {
            font-weight: 700;
          }
        }
      }
    }
    .apply-changes {
      align-self: flex-end;
      border-radius: 5px;
      margin-bottom: 8px;
      width: 130px;
      font-size: 13px;
      font-weight: 500;
      padding: 12px 33px;
      background: #4c6870;
      color: var(--color-light);
      border: 1px solid transparent;
      transition: 0.3s ease;
      :hover {
        color: var(--color-dark);
        background: transparent;
        border: 1px solid var(--color-dark);
      }
    }
  }
  .buttongroups {
    display: flex;
    align-items: center;
    margin-left: 10px;
    position: relative;
    ::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      border: 5px solid #bbc6c9;
      width: 100%;
      border-radius: 10px;
    }
    button {
      border: none;
      background: none;
      font-size: 15px;
      font-weight: 600;
      padding: 20px 5px;
      padding-bottom: 30px;
      color: #ccd4d6;
      position: relative;
      :hover {
        color: var(--color-dark);
        cursor: pointer;
      }
      :not(:first-child) {
        margin-left: 50px;
      }
      .underline {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        border: 5px solid var(--app-color-dark);
        z-index: 9;
        border-radius: 10px;
      }
    }
    .active {
      color: var(--color-dark);
    }
  }
  > .grid-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 30px;
    gap: 20px;
    @media (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .grid-column {
      background: var(--color-light);
      border-radius: 5px;
      box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
      overflow: hidden;
      position: relative;
      transition: 0.5s ease;

      .card-header {
        width: 100%;
        background: var(--app-color-dark);
        padding: 15px;
        color: var(--color-light);
        h4 {
          font-size: 15px;
          font-weight: 600;
        }
        button {
          padding: 5px 8px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 5px;
          border: none;
          outline: none;
          font-size: 11px;
          font-weight: 500;
          color: var(--color-light);
          margin-top: 10px;
        }
      }
      .card-body {
        padding: 15px;
        .row {
          display: flex;
          padding: 15px 0;
          .column {
            h5 {
              font-size: 13px;
              font-weight: 500;
              line-height: 1.7;
              color: #ccd4d6;
            }
            p {
              font-size: 13px;
              font-weight: 700;
              line-height: 1.7;
              color: var(--color-dark);
            }
          }
        }
      }
      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
  .load-search-results {
    .total-search-results {
      p {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.8;
        color: #ccd4d6;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        span {
          display: inline-flex;
          align-items: center;
          margin-left: 10px;
        }
      }
    }
    .button {
      background: var(--color-light);
      margin-bottom: 15px;
      button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        background: transparent;
        padding: 8px 10px;
        font-size: 13px;
        font-weight: 600;
        color: var(--color-dark);
        cursor: pointer;
        .left {
          span {
            margin-left: 10px;
            color: #ccd4d6;
          }
        }
      }
    }
    .grid-row {
      padding-top: 10px;
      .dataPanel {
        overflow: hidden;
        > .grid-row {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          margin-bottom: 15px;
          @media (max-width: 950px) {
            grid-template-columns: repeat(2, 1fr);
          }
          .grid-column {
            background: var(--color-light);
            border-radius: 5px;
            box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
            overflow: hidden;
            position: relative;
            transition: 0.5s ease;
            .card-header {
              width: 100%;
              background: var(--app-color-dark);
              padding: 15px;
              color: var(--color-light);
              h4 {
                font-size: 15px;
                font-weight: 600;
              }
              button {
                padding: 5px 8px;
                background: rgba(255, 255, 255, 0.3);
                border-radius: 5px;
                border: none;
                outline: none;
                font-size: 11px;
                font-weight: 500;
                color: var(--color-light);
                margin-top: 10px;
              }
            }
            .card-body {
              padding: 15px;
              .row {
                display: flex;
                padding: 15px 0;
                :first-child {
                  border-bottom: 1px solid #ebeeef;
                }
                .column {
                  :last-child {
                    margin-left: 20px;
                  }
                  h5 {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 1.7;
                    color: #ccd4d6;
                  }
                  p {
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 1.7;
                    color: var(--color-dark);
                  }
                }
              }
            }
            a {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
  .d-flex {
    display: flex;
  }
`;

type MarginProps = {
  margin?: string;
};

type TextStyledProps = {
  margin?: string;
  size?: string;
  weight?: string;
  color?: string;
  lineHeight?: string;
};

type ButtonTextProps = {
  margin?: string;
};

type CardStyledProps = {
  padding?: string;
  margin?: string;
};

const Margin = styled.div<MarginProps>`
  margin: ${(props: MarginProps) => props.margin};
`;

const TextStyled = styled.h2<TextStyledProps>`
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color || 'var(--color-dark)'};
  line-height: ${(props) => props.lineHeight || '25px'};
  svg {
    margin-right: 8px;
  }
`;

const ButtonText = styled.button<ButtonTextProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: ${Fonts.secondary};
  font-weight: 500;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: -0.408px;
  color: var(--app-color-dark);
  margin: ${(props) => props.margin};
`;

const CardStyled = styled.div<CardStyledProps>`
  position: relative;
  background: var(--color-light);
  padding: ${(props) => props.padding || '20px'};
  border-radius: 5px;
  box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
  margin-bottom: ${(props) => props.margin || '20px'};
`;

export { Margin, TextStyled, ButtonText, CardStyled };

export default Wrapper;
