import { Colors } from 'silal_app_base_react/src/config/theme';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
  flex-direction: column;
  border-radius: 20px 0px 0px 20px;
  background: white;
  padding: 0 40px;
  font-family: 'Lato', sans-serif;
  position: relative;
  .custom_error_for_all_fields {
    padding: 10px;
    background: #fa4d4d;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    border-radius: 5px;
  }
  .top-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .title {
      padding: 50px 0;
      h1 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
      h3 {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .buttonGroups {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 3px;
      background: #f5f5f5;
      border-radius: 5px;
      button {
        width: 100%;
        transition: 0.3s ease;
        :hover {
          box-shadow: 1px 3px 18px rgba(0, 0, 0, 0.11);
        }
      }
    }
    .form {
      width: 40vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form-control-mobile {
        margin-top: 20px;
        label {
          font-size: 15px;
          font-weight: 700;
          color: #4c6870;
        }
        .row {
          display: flex;
          justify-content: center;
          align-items: center;
          .col1 {
            max-width: 150px;
            width: 100%;
            .value-container {
              border-right: none;
              border-radius: 5px 0px 0px 5px;
            }
          }
          .col2 {
            width: 100%;
            input {
              border-radius: 0px 5px 5px 0px;
            }
          }
        }
      }
      /* email form design  */
      .form-control-email {
        .row {
          margin-top: 20px;
          label {
            font-size: 15px;
            font-weight: 700;
            color: #4c6870;
          }
        }
      }
    }
  }
  .bottom-row {
    text-align: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 30px;
    width: 40%;
    left: 30%; /* Horizontally center */

    @media (max-height: 620px) {
      position: static;
      margin-top: 50px;
    }
    .forgot-pass-text {
      font-size: 15px;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 20px;
      a {
        text-decoration: none;
        margin-left: 10px;
        font-weight: bold;
        color: var(--app-color-dark);
      }
    }
  }
`;

export default Wrapper;
