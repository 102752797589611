import BackButton from 'components/back_button';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import { OrderStatusTag } from 'silal_app_base_react/src/pages/orders_archive/components/shared_components';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  PopupWithConfirmation,
  PopupWithOneInput,
} from 'silal_app_base_react/src/components/popups/index';
import {
  MANAGEMENT_NOTES_RETURN_FLAG,
  SEPERATOR_BETWEEN_MESSAGES,
  SEPERATOR_BETWEEN_MESSAGE_FIELDS,
} from 'src/data/constants/messages';
import {
  DEFAULT_CITY,
  DateTimeFormateLeftToRightWithSeconds,
} from 'silal_app_base_react/src/config/constants';
import { RootState } from 'data/types/state';
import { ShiftsAddOrChoosePopup } from 'pages/logistics/routing_schedule/modals/shifts_modal';
import moment from 'moment';
import { getFilteredShifts } from 'silal_app_base_react/src/utils/functions/shifts_functions';
import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import { changeShiftTimeZone } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import {
  ISRAEL_TIMEZONE,
  UTC,
} from 'silal_app_base_react/src/config/constants';
import { OrderManagementButtonGroupWithDropdownMenu } from './order_management_buttons';
import {
  ManagementOrderActions,
  ManagementOrderActionsData,
  ManagementOrderActionsTypes,
  getRelevantOrderActionsPopupsMessages,
} from '../functions/orders_functions';
import { Button } from '@nextui-org/react';
import ShiftsRepository from 'data/repositories/shifts_repository';
import { PackageEditingModal } from 'silal_app_base_react/src/components/package_editing_modal';
import {
  DELIVERY_SHIPMENT_STATUS,
  Package,
} from 'silal_app_base_react/src/data/types/shipping';
import ManagementNotes from './management_note';

function OrderProfileToprow({
  orderDetails,
  doRefresh,
}: {
  orderDetails: OrderDetails;
  doRefresh: () => void;
}) {
  const user = useSelector((state: RootState) => state.Auth.user);
  const navigate = useNavigate();
  const [date, setDate] = useState(moment(new Date()).format('YYYY-M-d'));
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [timezonedShites, setTimezonedShites] = useState<Shift[]>([]);
  const [showShiftsModal, setShowShiftsModal] = useState(false);

  const [selectedPickupShiftId, setSelectedPickupShiftId] = useState('');

  const [selectingSecondShift, setSelectingSecondShift] = useState(false);

  const [singleInputModal, setSingleInputModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showPackageEditingModal, setShowPackageEditingModal] = useState(false);

  const [selectedOption, setSelectedOption] =
    useState<ManagementOrderActionsData>(
      ManagementOrderActions[ManagementOrderActionsTypes.add_management_note],
    );

  const [
    relevantOrderActionsPopupsMessages,
    setRelevantOrderActionsPopupsMessages,
  ] = useState(
    getRelevantOrderActionsPopupsMessages({
      action: ManagementOrderActionsTypes.add_management_note,
      id: orderDetails.id,
    }),
  );

  useEffect(() => {
    const loadAppropriateShifts = async () => {
      if (
        selectedOption.action ===
          ManagementOrderActionsTypes.update_pickup_dropoff_shift ||
        selectedOption.action ===
          ManagementOrderActionsTypes.reschedule_return_to_store
      ) {
        await ShiftsRepository.getShiftsWhereStoreIsOpen(
          orderDetails.store_id,
        ).then((res) => {
          if (!res) {
            return;
          }
          setShifts(res);
        });
      } else if (
        selectedOption.action ===
        ManagementOrderActionsTypes.reschedule_silal_return
      ) {
        await ShiftsRepository.getShifts({
          city_id: DEFAULT_CITY,
          futureOnly: true,
        }).then((res) => {
          if (!res) {
            return;
          }
          setShifts(res);
        });
      }
    };
    loadAppropriateShifts();
  }, [selectedOption]);

  useEffect(() => {
    const changeShiftsTimezone = () => {
      const editedShifts: Shift[] = [];
      shifts.forEach((shift) => {
        editedShifts.push(changeShiftTimeZone(shift, UTC, ISRAEL_TIMEZONE));
      });
      return editedShifts;
    };
    setTimezonedShites(changeShiftsTimezone());
  }, [shifts]);

  useEffect(() => {
    const res = getRelevantOrderActionsPopupsMessages({
      action: selectedOption.action,
      id: orderDetails.id,
    });
    setRelevantOrderActionsPopupsMessages(res);
  }, [selectedOption, orderDetails]);

  const addManagementNoteCallback = async (text: string) => {
    if (!text) {
      toast.warn('Please enter a note');
      return;
    }
    const currentDate = new Date();
    const note =
      formateServerDateToLocaleDate(
        currentDate,
        DateTimeFormateLeftToRightWithSeconds,
      ) +
      SEPERATOR_BETWEEN_MESSAGE_FIELDS +
      '(' +
      user.first_name +
      ' ' +
      user.last_name +
      '-' +
      user.current_user_id +
      ')' +
      SEPERATOR_BETWEEN_MESSAGE_FIELDS +
      text +
      SEPERATOR_BETWEEN_MESSAGES +
      (orderDetails.management_notes ? orderDetails.management_notes : '');
    await ManagementOrderActions[
      ManagementOrderActionsTypes.add_management_note
    ]
      .callback({
        order_id: orderDetails.id,
        note: note,
      })
      .then((res) => {
        if (res) {
          doRefresh();
          setSingleInputModal(false);
          return;
        }
      });
  };

  const singleInputCallback = async (input: string) => {
    if (
      selectedOption.action === ManagementOrderActionsTypes.add_management_note
    ) {
      return await addManagementNoteCallback(input);
    } else if (
      selectedOption.action ===
      ManagementOrderActionsTypes.collect_shipment_from_captain
    ) {
      return await confirmActionCallback(input);
    }
  };

  const confirmActionCallback = async (input?: string) => {
    // Used for actions that require confirmation
    if (selectedOption.action === ManagementOrderActionsTypes.markAsReturning) {
      return await addManagementNoteCallback(MANAGEMENT_NOTES_RETURN_FLAG);
    }
    await ManagementOrderActions[selectedOption.action]
      .callback({
        order_id: orderDetails.id,
        shipment_id: input,
      })
      .then((res) => {
        if (!res) return;
        if (input) setSingleInputModal(false);
        setConfirmationModal(false);
        doRefresh();
        return;
      });
  };

  const reschedulePickupAndOrDropoffActionCallback = async (
    pickupShiftId: string,
    dropoffShiftId?: string,
    hasAnyShipments?: boolean,
  ) => {
    // Used for updating pickup and dropoff shifts
    if (!pickupShiftId) {
      toast.warn('Please select a pickup shift');
      return;
    }
    // Need to add packages manually
    if (!hasAnyShipments) {
      // If no packages are selected, show the package editing modal and inside it, the user can select packages and the callback will be there
      setShowPackageEditingModal(true);
    } else {
      await ManagementOrderActions[selectedOption.action]
        .callback({
          order_id: orderDetails.id,
          new_pickup_shift_id: pickupShiftId,
          new_dropoff_shift_id:
            dropoffShiftId === '' ? undefined : dropoffShiftId,
        })
        .then((res) => {
          if (!res) return;
          doRefresh();
          setShowShiftsModal(false);
          setSelectedPickupShiftId('');
          setSelectingSecondShift(false);
          return;
        });
    }
  };

  const rescheduleReturnToStoreOrSilalActionCallback = async (
    packages: Package[],
  ) => {
    // Used for rescheduling return to store and silal return
    await ManagementOrderActions[selectedOption.action]
      .callback({
        orderId: orderDetails.id,
        order_id: orderDetails.id,
        shift_id: selectedPickupShiftId,
        packages: packages,
      })
      .then((res) => {
        if (!res) return;
        doRefresh();
        setShowPackageEditingModal(false);
        setSelectedPickupShiftId('');
        return;
      });
  };

  const ShiftsModalFirstButton = () => {
    return selectedOption.action !==
      ManagementOrderActionsTypes.update_pickup_dropoff_shift
      ? undefined
      : selectingSecondShift
        ? () => (
            <Button
              onClick={() => {
                setSelectingSecondShift(false);
              }}
            >
              Back to selecting pickup shift & clear dropoff shift
            </Button>
          )
        : (shiftId: string) => (
            <Button
              onClick={() => {
                if (shiftId === '') {
                  toast.warn('Please select a pickup shift');
                  return;
                }
                toast.info(
                  `Selected pickup shift: ${shiftId}, now select dropoff shift`,
                );
                setSelectedPickupShiftId(shiftId);
                setSelectingSecondShift(true);
              }}
            >
              Continue to selecting dropoff shift
            </Button>
          );
  };

  return (
    <Wrapper>
      <PopupWithConfirmation
        open={confirmationModal}
        headerTitle={relevantOrderActionsPopupsMessages?.headerTitle}
        bodyText={relevantOrderActionsPopupsMessages?.bodyText}
        buttonOneText={relevantOrderActionsPopupsMessages?.buttonOneText}
        buttonTwoText={relevantOrderActionsPopupsMessages?.buttonTwoText}
        onButtonOneClick={() => setConfirmationModal(false)}
        onButtonTwoClick={async () => await confirmActionCallback()}
      />
      <PopupWithOneInput
        open={singleInputModal}
        headerTitle={relevantOrderActionsPopupsMessages?.headerTitle}
        buttonOneText={relevantOrderActionsPopupsMessages?.buttonOneText}
        buttonTwoText={relevantOrderActionsPopupsMessages?.buttonTwoText}
        inputOneLabel={
          relevantOrderActionsPopupsMessages?.inputOneLabel || 'Type here'
        }
        inputOnePlaceholder={
          relevantOrderActionsPopupsMessages?.inputOnePlaceholder || ''
        }
        inputOneValidator={(e) => {
          if (!e) {
            return true;
          }
          return false;
        }}
        inputOneAsTextAreaInsteadOfSingleInput={
          selectedOption.action ===
          ManagementOrderActionsTypes.add_management_note
        }
        onButtonOneClick={() => setSingleInputModal(false)}
        onButtonTwoClick={async (e: string) => await singleInputCallback(e)}
      />
      <ShiftsAddOrChoosePopup
        modal={showShiftsModal}
        closeModal={() => setShowShiftsModal(false)}
        date={moment(date).toISOString()}
        setDate={setDate}
        getFilteredShifts={(day_number: string) =>
          getFilteredShifts(timezonedShites, new Date(day_number))
        }
        customFirstButton={ShiftsModalFirstButton()}
        selectShift={async (shiftId) => {
          setSelectedPickupShiftId(shiftId);
          if (
            selectedOption.action ===
              ManagementOrderActionsTypes.update_order_dropoff_shift ||
            selectedOption.action ===
              ManagementOrderActionsTypes.update_pickup_dropoff_shift
          ) {
            await reschedulePickupAndOrDropoffActionCallback(
              !selectingSecondShift ? shiftId : selectedPickupShiftId,
              selectingSecondShift ? shiftId : undefined, // Passed like this to overcome propogation delay of the setState
              orderDetails.shipments?.length > 0,
            );
          } else if (
            selectedOption.action ===
              ManagementOrderActionsTypes.reschedule_return_to_store ||
            selectedOption.action ===
              ManagementOrderActionsTypes.reschedule_silal_return
          ) {
            setShowShiftsModal(false);
          }
        }}
        allShifts={timezonedShites}
        selectShiftButtonText={
          selectedOption.action ===
          ManagementOrderActionsTypes.update_pickup_dropoff_shift
            ? selectingSecondShift
              ? 'Select Dropoff Shift'
              : 'Select Pickup Shift'
            : 'Select Shift'
        }
        customTitle={
          <>
            {!selectingSecondShift
              ? `Updating pickup shift for order #${orderDetails.id}, currently looking at ${moment(
                  date,
                ).format('DD/MM/YYYY')}`
              : `Updating dropoff shift for order #${orderDetails.id}, currently looking at ${moment(
                  date,
                ).format('DD/MM/YYYY')}`}
          </>
        }
      />{' '}
      <PackageEditingModal
        open={showPackageEditingModal}
        headerTitle={selectedOption.label}
        onButtonOneClick={async () => setShowPackageEditingModal(false)}
        orderDetails={orderDetails}
        confirmAction
        onButtonTwoClick={async (packages) => {
          if (
            selectedOption.action ===
              ManagementOrderActionsTypes.update_order_dropoff_shift ||
            selectedOption.action ===
              ManagementOrderActionsTypes.update_pickup_dropoff_shift
          ) {
            await ManagementOrderActions[selectedOption.action]
              .callback({
                order_id: orderDetails.id,
                new_pickup_shift_id: selectedPickupShiftId,
                new_dropoff_shift_id:
                  selectedPickupShiftId === ''
                    ? undefined
                    : selectedPickupShiftId,
                packages: packages,
              })
              .then((res) => {
                if (!res) return;
                doRefresh();
                setShowShiftsModal(false);
                setSelectedPickupShiftId('');
                setSelectingSecondShift(false);
                setShowPackageEditingModal(false);
                return;
              });
          } else {
            await rescheduleReturnToStoreOrSilalActionCallback(packages);
          }
        }}
      />
      <div className="row">
        <div
          className="top-row"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <BackButton primaryIcon={false} />
            <div className="customer-name-id">
              <h2 className="poppins-font">
                Order #{orderDetails.id}
                {OrderStatusTag(orderDetails.status)}
              </h2>
              <h5
                style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  padding: 0,
                  margin: 0,
                  color: Colors.greenMain,
                }}
              >
                <Link
                  to={`/databases/stores/${orderDetails.store_id}/current-orders`}
                  style={{ color: Colors.greenMain }}
                >
                  #{orderDetails.store_id}{' '}
                  {orderDetails.store_name_en +
                    ' | ' +
                    orderDetails.store_name_ar}
                </Link>
                <p
                  style={{
                    color: Colors.alternateText,
                  }}
                >
                  {formateServerDateToLocaleDate(orderDetails.placing_date)}
                </p>
              </h5>
            </div>
          </div>
          <div>
            <ManagementNotes message={orderDetails.management_notes || ''} />
            <OrderManagementButtonGroupWithDropdownMenu
              orderStatus={orderDetails.status}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              handleButtonClick={() => {
                if (
                  selectedOption.action ===
                    ManagementOrderActionsTypes.add_management_note ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.collect_shipment_from_captain
                ) {
                  setSingleInputModal(true);
                } else if (
                  selectedOption.action ===
                    ManagementOrderActionsTypes.cancelOrder ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.initiate_customer_return ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.mark_return_as_inspected ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.return_undelivered_order ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.collect_return_from_customer ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.order_return_rejected_at_silal ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.markAsReturning
                ) {
                  setConfirmationModal(true);
                } else if (
                  selectedOption.action ===
                    ManagementOrderActionsTypes.reschedule_return_to_store ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.reschedule_silal_return ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.update_order_dropoff_shift ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.update_pickup_dropoff_shift
                ) {
                  setShowShiftsModal(true);
                } else if (
                  selectedOption.action ===
                    ManagementOrderActionsTypes.mark_shipment_as_failed_dropoff ||
                  selectedOption.action ===
                    ManagementOrderActionsTypes.mark_shipment_as_failed_pickup
                ) {
                  navigate(
                    `/databases/orders-archive/${orderDetails.id}/shipments`,
                  );
                  return;
                } else if (
                  selectedOption.action ===
                  ManagementOrderActionsTypes.refundOrder
                ) {
                  navigate(
                    `/databases/orders-archive/${orderDetails.id}/ledger`,
                  );
                  return;
                }
              }}
            />
          </div>
        </div>
        <div className="top-row-buttons poppins-font">
          {OrderProfileNavData.map((nav) => (
            <NavLink
              key={nav.id}
              to={nav.pathName}
              className={(info) => (info.isActive ? 'active' : '')}
              state={orderDetails}
              end
            >
              {nav.name}
            </NavLink>
          ))}
        </div>
      </div>
    </Wrapper>
  );
}

const OrderProfileNavData = [
  {
    id: 'f5343',
    name: 'Overview',
    pathName: 'overview',
  },
  {
    id: 'dh51',
    name: 'Shipments',
    pathName: 'shipments',
  },
  {
    id: 'zx51',
    name: 'Ledger',
    pathName: 'ledger',
  },
];

const Wrapper = styled.div`
  .row {
    .top-row {
      display: flex;
      align-items: flex-start;
      > button {
        margin-top: 0;
      }

      .customer-name-id {
        margin-left: 20px;
        h2 {
          font-size: 25px;
          font-weight: 700;
          display: flex;
          align-items: center;
          span {
            padding: 5px 8px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.4;
            margin-left: 15px;
          }
        }

        p {
          margin-top: 7px;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 700;
          line-height: 1.8;
          color: #ccd4d6;
        }
      }
    }
    .add-note-button {
      background: var(--app-color-dark);
      color: var(--color-light);
      border: none;
      margin-left: auto;
      float: right;
    }
    .top-row-buttons {
      padding: 15px;
      a {
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        padding: 8px 20px;
        color: #4c6870;
        border-radius: 5px;
        margin-right: 15px;
        transition: 0.3s ease;
        :hover {
          background: var(--app-color-dark);
          color: var(--color-light);
        }
      }
      .active {
        background: var(--app-color-dark);
        color: var(--color-light);
      }
    }
  }
`;

export default OrderProfileToprow;
