import { PermissionTypes } from 'silal_app_base_react/src/data/types/users';
import Icon1 from 'assets/images/sidenavicons/icon1.svg';
import Icon2 from 'assets/images/sidenavicons/icon2.svg';
import Icon3 from 'assets/images/sidenavicons/icon3.svg';
import Icon4 from 'assets/images/sidenavicons/icon4.svg';
import Icon5 from 'assets/images/sidenavicons/icon5.svg';
import Icon6 from 'assets/images/sidenavicons/icon6.svg';
import Icon7 from 'assets/images/sidenavicons/icon7.svg';
import Icon8 from 'assets/images/sidenavicons/icon8.svg';
import Icon9 from 'assets/images/sidenavicons/icon9.svg';
import Icon10 from 'assets/images/sidenavicons/icon10.svg';
import Icon11 from 'assets/images/sidenavicons/icon11.svg';

export type ManagementAvailableRoutes = {
  dashboard: boolean;
  databases: boolean;
  payments: boolean;
  coupons: boolean;
  products: boolean;
  inquiries: boolean;
  categories: boolean;
  templates: boolean;
  image_library: boolean;
  banners: boolean;
  logistics: boolean;
  settings: boolean;
};

export function getRoutingPermissionsMap(
  level_enum: number,
): ManagementAvailableRoutes {
  // For faster development, we will use the level_enum to determine the sidebar permissions
  const admin = level_enum === PermissionTypes.ADMIN ? true : false;
  const accountant = level_enum === PermissionTypes.ACCOUNTANT ? true : false;
  const customer_support =
    level_enum === PermissionTypes.CUSTOMER_SUPPORT ? true : false;
  const data_entry = level_enum === PermissionTypes.DATA_ENTRY ? true : false;
  const logistics =
    level_enum === PermissionTypes.LOGISTICS_MANAGER ? true : false;
  const technical_support =
    level_enum === PermissionTypes.TECHNICAL_SUPPORT ? true : false;

  // The global permissions for the sidebar using the flags above
  const sidebarPermissions = {
    dashboard: true,
    databases: admin || accountant || customer_support,
    payments: admin || accountant,
    coupons: admin,
    products: admin || customer_support || data_entry,
    inquiries: admin || customer_support || technical_support,
    categories: admin || customer_support || technical_support,
    templates: admin || data_entry || technical_support,
    image_library: admin || data_entry,
    banners: admin || customer_support || technical_support,
    logistics: admin || logistics,
    settings: admin,
  };
  return sidebarPermissions;
}

export const getAppropriateSidebar = (level_enum: number) => {
  const routingPermissions = getRoutingPermissionsMap(level_enum);

  const menu = [
    routingPermissions.dashboard && {
      id: '01',
      icon: Icon1,
      title: 'Dashboard',
      path: '/dashboard',
    },
    routingPermissions.databases && {
      id: '02',
      icon: Icon2,
      title: 'Databases',
      path: '/databases',
      dropdown: [
        {
          id: '001',
          dtitle: 'Users',
          path: 'users',
        },
        // {
        //   id: '002',
        //   dtitle: 'Customers',
        //   path: 'customers',
        // },
        {
          id: '003',
          dtitle: 'Stores',
          path: 'stores',
        },
        // {
        //   id: '004',
        //   dtitle: 'Captains',
        //   path: 'drivers',
        // },
        {
          id: '005',
          dtitle: 'Orders',
          path: 'orders-archive',
        },
      ],
    },

    routingPermissions.coupons && {
      id: '04',
      icon: Icon3,
      title: 'Promotions',
      path: '/promotions',
    },
    routingPermissions.banners && {
      id: '09',
      icon: Icon8,
      title: 'Banners',
      path: '/banners',
    },
    routingPermissions.inquiries && {
      id: '05',
      icon: Icon4,
      title: 'Inquiries',
      path: '/inquiries',
    },
    routingPermissions.categories && {
      id: '06',
      icon: Icon5,
      title: 'Categories',
      path: '/categories',
    },
    routingPermissions.templates && {
      id: '07',
      icon: Icon9,
      title: 'Templates',
      path: '/templates',
      dropdown: [
        {
          id: '0015',
          dtitle: 'Search',
          path: 'search',
        },
        {
          id: '0016',
          dtitle: 'AI Listing',
          path: 'ai_listing',
        },
        {
          id: '0017',
          dtitle: 'Database',
          path: 'database',
        },
      ],
    },

    routingPermissions.payments && {
      id: '03',
      icon: Icon11,
      title: 'Payments',
      path: '/payment-reports',
    },
    routingPermissions.logistics && {
      id: '10',
      icon: Icon10,
      title: 'Logistics',
      path: '/logistics',
    },
    routingPermissions.image_library && {
      id: '08',
      icon: Icon7,
      title: 'Image Library',
      path: '/image-library',
    },
    routingPermissions.settings && {
      id: '11',
      icon: Icon6,
      title: 'Settings',
      path: '/settings',
    },
  ];

  return menu.filter((item) => item !== false);
};
