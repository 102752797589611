import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Spinner from 'silal_app_base_react/src/components/spinner';
import UsersRepository from 'data/repositories/users_repository';
import { AbstractUserInfo } from 'silal_app_base_react/src/data/types/users';
import UserProfileToprow from '../components/top_row';

export default function UserProfileParentRouteComponent() {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<AbstractUserInfo>();

  useEffect(() => {
    async function fetchUser() {
      await UsersRepository.getUserDetails(parseInt(userId!)).then((res) => {
        if (res) setUser(res as AbstractUserInfo);
      });
    }
    fetchUser();
  }, [userId]);

  return (
    <div>
      {user ? (
        <div>
          <UserProfileToprow user={user} />
          <Outlet context={user} />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
