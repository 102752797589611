import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'styles/style';
import {
  actionAddCity,
  actionAddTown,
  actionDeleteCity,
  actionDeleteTown,
  actionGetCities,
  actionGetTowns,
  actionSetCity,
  actionSetRegion,
  actionUpdateCity,
  actionUpdateTown,
} from 'store/locations/locations_actions';
import RightArrow from 'assets/images/right-arrow.svg';
import { Wrapper } from './operating_locations.styled';
import { RootState } from 'data/types/state';
import { SELECT_CITY_TOWN_MESSAGE } from 'data/constants/messages';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { toast } from 'react-toastify';
import AlertDialog from 'components/popup/popup_modal';
import { Link } from 'react-router-dom';
import NewOperatingLocationPopup from './components/add_new_town_or_city_modal';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { Button } from '@nextui-org/react';

export enum ActionType {
  NONE = 'none',
  addCity = 'Add New City',
  addTown = 'Add New Town',
  updateCity = 'Update City',
  updateTown = 'Update Town',
}

function OperatingLocations() {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('city');
  const [status, setStatus] = useState(ActionType.NONE);

  const [data, setData] = useState<any>({
    name_ar: '',
    name_en: '',
    delivery_fee: '',
    cross_order_delivery_fee_discount: '',
    lat: 0,
    lng: 0,
    region: 1,
    city_id: '',
    town_id: '',
    active: true,
  });

  const { cities = [], towns = [] } = useSelector(
    (state: RootState) => state?.Locations,
  );

  const filteredCities = () => {
    return cities?.filter(
      (item) =>
        item.name_en.toLowerCase().match(search.toLowerCase()) ||
        item.name_ar.toLowerCase().match(search.toLowerCase()),
    );
  };
  const filteredTowns = () => {
    return towns?.filter(
      (item) =>
        item.name_en.toLowerCase().match(search.toLowerCase()) ||
        item.name_ar.toLowerCase().match(search.toLowerCase()),
    );
  };

  useEffect(() => {
    dispatch(actionGetCities({ regionId: data?.region }));
    dispatch(actionSetRegion(data?.region));
    setData({ ...data, town_id: '', city_id: '' });
  }, [data?.region]);

  useEffect(() => {
    if (data?.city_id !== '' && data?.city_id)
      dispatch(actionGetTowns({ city_id: data.city_id }));
    dispatch(actionSetCity(data?.city_id));
    setData({ ...data, town_id: '' });
  }, [data?.city_id]);

  const validateUpdatedInfo = (updatedInfo: any, type: string): boolean => {
    if (!FormValidators.isEnglish(updatedInfo.name_en)) {
      toast.warn('Please enter a valid English name');
      return false;
    }
    if (!FormValidators.isArabic(updatedInfo.name_ar)) {
      toast.warn('Please enter a valid Arabic name');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.delivery_fee)) && type === 'City') {
      toast.warn('Please enter a valid delivery fee');
      return false;
    }
    if (
      isNaN(parseFloat(updatedInfo.cross_order_delivery_fee_discount)) &&
      type === 'City'
    ) {
      toast.warn('Please enter a valid cross order delivery fee discount');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.lng))) {
      toast.warn('Please enter a valid longitude');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.lat))) {
      toast.warn('Please enter a valid latitude');
      return false;
    }
    return true;
  };

  const save = (updatedInfo: any) => {
    // Validate location data
    if (!validateUpdatedInfo(updatedInfo, type)) {
      return false;
    }

    if (updatedInfo?.town_id !== '') {
      dispatch(
        actionUpdateTown({
          ...updatedInfo,
          lat: parseFloat(updatedInfo.lat),
          lng: parseFloat(updatedInfo.lng),
        }),
      );
      return true;
    } else if (updatedInfo?.city_id !== '') {
      dispatch(
        actionUpdateCity({
          ...updatedInfo,
          lat: parseFloat(updatedInfo.lat),
          lng: parseFloat(updatedInfo.lng),
        }),
      );
      return true;
    } else {
      alert('please select region!');
    }
    return false;
  };

  const addNew = () => {
    if (!validateUpdatedInfo(data, type)) {
      setAddNewModal(false);
      return;
    }

    if (type === 'city') {
      dispatch(
        actionAddCity({
          ...data,
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
        }),
      );
    } else if (type === 'town') {
      dispatch(
        actionAddTown({
          ...data,
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
        }),
      );
    } else {
      toast.warn(SELECT_CITY_TOWN_MESSAGE);
    }
    setAddNewModal(false);
  };

  const remove = () => {
    if (data?.town_id !== '') {
      dispatch(actionDeleteTown(data));
    } else if (data?.city_id !== '') {
      dispatch(actionDeleteCity(data));
    }
    setDeleteModal(false);
  };

  const regions = [
    { id: 1, name: 'Region 1' },
    { id: 2, name: 'Region 2' },
  ];

  return (
    <Wrapper>
      <PopupWithConfirmation
        open={deleteModal}
        headerTitle="Delete"
        bodyText={`Are you sure you want to delete ${data?.name_en}? This action cannot be undone.`}
        onButtonTwoClick={remove}
        onButtonOneClick={() => {
          setDeleteModal(false);
        }}
      />
      {addNewModal && (
        <AlertDialog
          title="Add New Location"
          subtitle={`Are you sure you want to add ${
            data?.name_en ? data?.name_en : data?.name_ar
          }?`}
          onAgree={addNew}
          onDisagree={() => {
            setAddNewModal(false);
          }}
        />
      )}
      {saveModal && (
        <NewOperatingLocationPopup
          open={saveModal}
          handleOpen={setSaveModal}
          data={data}
          setData={setData}
          onSubmit={save}
          type={type}
          status={status}
          setAddNewModal={setAddNewModal}
        />
      )}
      <Label color="#00273380">Search by name</Label>
      <div className="search-div">
        <Icon icon="fe:search" className="icon-label " />
        <input
          type="text"
          placeholder="Select region or city then search via EN or AR name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className="main-body ml-3">
        <div className="categories-main row p-2">
          <div className="category-col col-3">
            {regions.map((region) => (
              <div
                key={region.id}
                className={`item ${data?.region === region.id ? 'active row' : 'row'}`}
                onClick={() => {
                  setType('city');
                  setData({ region: region.id });
                }}
              >
                <div className="details col-12">{region.name}</div>
                <div className="actions col-12">
                  <Button
                    title="Add new city"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus(ActionType.addCity);
                      setType('city');
                      setData({
                        ...data,
                        name_ar: '',
                        name_en: '',
                        city_id: '',
                        town_id: '',
                        delivery_fee: 0,
                        cross_order_delivery_fee_discount: 0,
                        lat: '',
                        lng: '',
                        id: '',
                      });
                      setSaveModal(true);
                    }}
                    className="icon-btn"
                    isIconOnly
                  >
                    <Icon icon="icon-park:add" width="20" height="20" />
                  </Button>
                  <img src={RightArrow} alt="right_icon" />
                </div>
              </div>
            ))}
          </div>
          <div className="category-col col-5">
            {filteredCities().map((city, index) => (
              <div
                key={index}
                className={`item ${
                  data?.city_id?.toString() === city?.id.toString()
                    ? 'active row'
                    : 'row'
                }`}
                onClick={() => {
                  setType('city');
                  setData({
                    ...data,
                    name_ar: city.name_ar,
                    name_en: city.name_en,
                    city_id: city.id,
                    town_id: '',
                    delivery_fee: city.delivery_fee,
                    cross_order_delivery_fee_discount:
                      city.cross_order_delivery_fee_discount,
                    lat: parseFloat(city.lat),
                    lng: parseFloat(city.lng),
                    id: city.id,
                  });
                }}
              >
                <div className="details col-12" title="Click to show towns">
                  <p>{city.name_en} </p>
                </div>
                <div className="actions col-12">
                  {/*
                      <Switch
                        title="active"
                        color="success"
                        className="mr-5"
                        checked={data?.active}
                        onChange={() =>
                          setData({ ...data, active: !data?.active })
                        }
                      />
                      */}
                  <Link
                    to={`https://www.google.com/maps/search/?api=1&query=${city.lat},${city.lng}`}
                    target="_blank"
                    title="Click to show on map"
                  >
                    <Icon
                      className="icon-btn"
                      icon="hugeicons:location-04"
                      href="https://iconscout.com/icons/location"
                      width="20"
                      height="20"
                      style={{ color: 'black' }}
                    />
                  </Link>

                  <Button
                    title="Add new town"
                    onClick={(e) => {
                      e.stopPropagation();
                      setStatus(ActionType.addTown);
                      setType('town');
                      setData({
                        ...data,
                        name_ar: '',
                        name_en: '',
                        city_id: city.id,
                        delivery_fee: city.delivery_fee,
                        cross_order_delivery_fee_discount:
                          city.cross_order_delivery_fee_discount,
                        lat: '',
                        lng: '',
                        id: '',
                      });
                      setSaveModal(true);
                    }}
                    className="icon-btn"
                    isIconOnly
                  >
                    <Icon icon="icon-park:add" width="20" height="20" />
                  </Button>
                  <Button
                    className="icon-btn"
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                    title="Delete"
                    isIconOnly
                  >
                    <Icon
                      icon="ci:trash-full"
                      width="20"
                      style={{ color: 'red' }}
                    />
                  </Button>
                  <Button
                    title="Edit"
                    onClick={() => {
                      setStatus(ActionType.updateCity);
                      setSaveModal(true);
                    }}
                    isIconOnly
                    className="icon-btn"
                  >
                    <Icon
                      icon="lucide:edit"
                      width="20"
                      height="20"
                      style={{ color: 'green' }}
                    />
                  </Button>
                  <img
                    src={RightArrow}
                    alt="right_icon"
                    title="Click to show towns"
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="category-col col-4">
            {filteredTowns().map((town, index) => {
              return (
                <div
                  key={index}
                  className={`item ${
                    data?.town_id?.toString() === town?.id.toString()
                      ? 'active row'
                      : 'row'
                  }`}
                  onClick={() => {
                    setType('town');
                    setData({
                      ...data,
                      name_ar: town.name_ar,
                      name_en: town.name_en,
                      town_id: town.id,
                      lat: parseFloat(town.lat),
                      lng: parseFloat(town.lng),
                      id: town.id,
                    });
                  }}
                >
                  <div className="details col-12" title="click to show">
                    <p>{town.name_en} </p>
                  </div>

                  <div className="actions col-12">
                    <Link
                      to={`https://www.google.com/maps/search/?api=1&query=${town.lat},${town.lng}`}
                      target="_blank"
                      title="Click to show on map"
                    >
                      <Icon
                        className="icon-btn"
                        icon="hugeicons:location-04"
                        width="20"
                        height="20"
                        href="https://iconscout.com/icons/location"
                        style={{ color: 'black' }}
                      />
                    </Link>
                    <Button
                      className="icon-btn"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                      isIconOnly
                      title="Delete"
                    >
                      <Icon
                        icon="ci:trash-full"
                        width="20"
                        height="20"
                        style={{ color: 'red' }}
                      />
                    </Button>
                    <Button
                      title="Edit"
                      onClick={() => {
                        setStatus(ActionType.updateTown);

                        setSaveModal(true);
                      }}
                      isIconOnly
                      className="icon-btn"
                    >
                      <Icon
                        icon="lucide:edit"
                        width="20"
                        height="20"
                        style={{ color: 'green' }}
                      />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default OperatingLocations;
