import { Row } from 'antd';
import {
  CustomerUserInfo,
  CaptainUserInfo,
  Manager,
  MerchantUserInfo,
  PermissionTypesMap,
} from 'silal_app_base_react/src/data/types/users';
import { Link } from 'react-router-dom';
const CustomerDatabaseCard = (customer: CustomerUserInfo) => (
  <div className="grid-column" key={customer.id}>
    <div className="card-header">
      <h4>
        #{customer.id} {customer.first_name + ' ' + customer.last_name}
      </h4>
      <button>{customer.type}</button>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="column">
          <Row>
            <h5
              style={{
                marginRight: '10px',
              }}
            >
              Phone:{' '}
            </h5>
            <p>{customer.phone ?? '--- ---'}</p>
          </Row>{' '}
          <Row>
            <h5
              style={{
                marginRight: '10px',
              }}
            >
              Email:{' '}
            </h5>
            <p>{customer.email ?? '--- ---'}</p>
          </Row>{' '}
          <Row>
            <h5
              style={{
                marginRight: '10px',
              }}
            >
              User ID:{' '}
            </h5>
            <p>{customer.id ?? '--- ---'}</p>
          </Row>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <Row>
            <h5
              style={{
                marginRight: '10px',
              }}
            >
              Finished Signup:{' '}
            </h5>
            <p>{customer.finished_signup ? 'Yes' : 'No'}</p>
            <Link
              to={`/databases/customers/${customer.id}/profile`}
              state={{
                filters: {
                  customers: [customer.id],
                },
              }}
            />
          </Row>
        </div>
      </div>
    </div>
  </div>
);

const SellerDatabaseCard = (merchant: MerchantUserInfo) => (
  <div className="grid-column" key={merchant.id}>
    <div className="card-header">
      <h4>
        #{merchant.id} {merchant.first_name + ' ' + merchant.last_name}
      </h4>
      <button>{merchant.type}</button>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="column">
          <Row>
            <h5>Phone: </h5>
            <p>{merchant.phone ?? '--- ---'}</p>
          </Row>
          <Row>
            <h5>Email: </h5>
            <p>{merchant.email ?? '--- ---'}</p>
          </Row>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <Row>
            <h5>Verified Phone: </h5>
            <p>{merchant.verified_phone ? 'Yes' : 'No'}</p>
          </Row>
          <Row>
            <h5>Verified Email: </h5>
            <p>{merchant.verified_email ? 'Yes' : 'No'}</p>
          </Row>
        </div>
      </div>
    </div>
  </div>
);

const ManagerDatabaseCard = (manager: Manager) => (
  <div className="grid-column" key={manager.id}>
    <div className="card-header">
      <h4>
        #{manager.id} {manager.first_name + ' ' + manager.last_name}
      </h4>
      <button>{PermissionTypesMap[manager.level_enum]}</button>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="column">
          <Row>
            <h5>Phone: </h5>
            <p>{manager.phone ?? '--- ---'}</p>
          </Row>
          <Row>
            <h5>User ID: </h5>
            <p>{manager.id ?? '--- ---'}</p>
          </Row>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <Row>
            <h5>Verified Phone: </h5>
            <p>{manager.verified_phone ? 'Yes' : 'No'}</p>
          </Row>
          <Row>
            <h5>Verified Email: </h5>
            <p>{manager.verified_email ? 'Yes' : 'No'}</p>
          </Row>
        </div>
      </div>
    </div>
  </div>
);

const CaptainDatabaseCard = (captain: CaptainUserInfo) => (
  <div className="grid-column" key={captain.id}>
    <div className="card-header">
      <h4>
        #{captain.id} {captain.first_name + ' ' + captain.last_name}
      </h4>
      <button>{captain.type}</button>
    </div>
    <div className="card-body">
      <div className="row">
        <div className="column">
          <Row>
            <h5>Phone: </h5>
            <p>{captain.phone ?? '--- ---'}</p>
          </Row>
          <Row>
            <h5>User ID: </h5>
            <p>{captain.id ?? '--- ---'}</p>
          </Row>
        </div>
      </div>
      <div className="row">
        <div className="column">
          <Row>
            <h5>Verified Phone: </h5>
            <p>{captain.verified_phone ? 'Yes' : 'No'}</p>
          </Row>
          <Row>
            <h5>Verified Email: </h5>
            <p>{captain.verified_email ? 'Yes' : 'No'}</p>
          </Row>
        </div>
      </div>
    </div>
  </div>
);
export {
  CustomerDatabaseCard,
  SellerDatabaseCard,
  ManagerDatabaseCard,
  CaptainDatabaseCard,
};
