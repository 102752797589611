import Wrapper from '../../../customers/screens/profile/customer_profile_screen.styled';
import { motion } from 'framer-motion';
import { FaCopy } from 'react-icons/fa6';
import { useOutletContext } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { AbstractUserInfo } from 'silal_app_base_react/src/data/types/users';
import { EyeClosed, EyeOpen, EyeSlashed, LockOff, LockOn } from 'akar-icons';

import React, { useState } from 'react';

const animate = {
  y: [60, 0],
  opacity: [0, 1],
  transition: { duration: 0.5 },
};

function UserProfile() {
  const user = useOutletContext<AbstractUserInfo>();

  return (
    <Wrapper>
      <div className="container grid-rows">
        <div className="card flex-fill">
          <div className="card-body">
            <AbstractUserContactInformation user={user} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export function AbstractUserContactInformation({
  user,
}: {
  user: AbstractUserInfo;
}) {
  const [showLoginHistory, setShowLoginHistory] = useState(false); // State to toggle visibility

  const toggleLoginHistory = () => {
    setShowLoginHistory((prev) => !prev); // Toggle the state
  };

  return (
    <motion.div animate={animate}>
      <div className="profile-container lato-font">
        <h2 className="center mb-10">User Info</h2>

        {/* First Name and Last Name in the same row */}
        <div className="col-12">
          <div className="row">
            <div className="col-md-4">
              <p>First Name</p>
              <h6>{user?.first_name ?? 'None'}</h6>
            </div>
            <div className="col-md-4">
              <p>Last Name</p>
              <h6>{user?.last_name ?? 'None'}</h6>
            </div>
            <div className="col-md-4">
              <p>ID</p>
              <h6>#{user?.id ?? 'None'}</h6>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            {/* Region */}
            <div className="col-md-4">
              <p>Region</p>
              <h6>{user?.region ?? 'None'}</h6>
            </div>

            {/* Locale */}
            <div className="col-md-4">
              <p>Locale</p>
              <h6>{user?.locale ?? 'None'}</h6>
            </div>

            <div className="col-md-4">
              <p>Type</p>
              <h6>{user?.type ?? 'None'}</h6>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            {/* Email */}
            <div className="col-md-6">
              <p>Email</p>
              <h6>
                {user?.email ?? 'None'}{' '}
                {user?.verified_email ? (
                  <span style={{ color: 'green' }}>✔️</span>
                ) : (
                  <span style={{ color: 'red' }}>❌</span>
                )}
              </h6>
            </div>

            {/* Phone Number */}
            <div className="col-md-6">
              <p>Phone number</p>
              <h6>
                {user?.phone ?? 'None'}{' '}
                {user?.verified_phone ? (
                  <span style={{ color: 'green' }}>✔️</span>
                ) : (
                  <span style={{ color: 'red' }}>❌</span>
                )}
              </h6>
            </div>
          </div>
        </div>

        {/* MFA and Password */}
        <div className="col-12">
          <div className="row">
            <div className="col-md-4">
              <p>Has Password</p>
              <h6>
                {user?.has_password ? (
                  <span style={{ color: 'green' }}>✔️</span>
                ) : (
                  <span style={{ color: 'red' }}>❌</span>
                )}
              </h6>
            </div>
            <div className="col-md-4">
              <p>MFA Enabled</p>
              <h6>
                {user?.enabled_mfa ? (
                  <span style={{ color: 'green' }}>✔️</span>
                ) : (
                  <span style={{ color: 'red' }}>❌</span>
                )}
              </h6>
            </div>
            {/* Finished Signup */}
            <div className="col-4">
              <p>Finished Signup</p>
              <h6>
                {user?.finished_signup ? (
                  <span style={{ color: 'green' }}>✔️</span>
                ) : (
                  <span style={{ color: 'red' }}>❌</span>
                )}
              </h6>
            </div>
          </div>
        </div>

        {/* Freshdesk ID */}
        <div className="col-12">
          <p>Freshdesk ID</p>
          <h6>
            {user?.freshdesk_restore_id !== null ? (
              <div
                className="d-flex justify-content-between flex-wrap"
                style={{ wordBreak: 'break-all' }}
              >
                {user?.freshdesk_restore_id}{' '}
                <FaCopy
                  size={20}
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      user?.freshdesk_restore_id ?? '',
                    );
                  }}
                  color={Colors.primaryIcon}
                />
              </div>
            ) : (
              'None'
            )}
          </h6>
        </div>

        {/* FCM Token */}
        <div className="col-12">
          <p>FCM Token</p>
          <h6>
            {user?.fcm_id !== null ? (
              <div
                className="d-flex justify-content-between flex-wrap"
                style={{ wordBreak: 'break-all' }}
              >
                <span>{user?.fcm_id}</span>
                <FaCopy
                  size={20}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '5px',
                    flexShrink: 0,
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(user?.fcm_id ?? '');
                  }}
                  color={Colors.primaryIcon}
                />
              </div>
            ) : (
              'None'
            )}
          </h6>
        </div>

        {/* Login History */}
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <p>Login History</p>
            <button className="btn btn-sm" onClick={toggleLoginHistory}>
              {showLoginHistory ? (
                <EyeClosed color={Colors.secondaryIcon} size={20} />
              ) : (
                <EyeOpen color={Colors.secondaryIcon} size={20} />
              )}
            </button>
          </div>
          {showLoginHistory && (
            <h6>
              {user?.login_history?.length > 0 ? (
                <ul>
                  {user?.login_history.slice(0, 10).map((login, index) => {
                    const [date, timeWithOffset] = login.datetime.split('T');
                    const time = timeWithOffset.split('+')[0]; // Remove the offset
                    return (
                      <>
                        <li key={index}>
                          <h6>
                            Date: {date}, Time: {time}
                          </h6>
                        </li>
                      </>
                    );
                  })}
                </ul>
              ) : (
                'None'
              )}
            </h6>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default UserProfile;
