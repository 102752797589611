import React from 'react';
import { Modal } from 'antd';
import { Input, Button } from '@nextui-org/react';
import { ActionType } from '../operating_locations';

export default function NewOperatingLocationPopup({
  open,
  handleOpen,
  data,
  setData,
  onSubmit,
  type,
  status,
  setAddNewModal,
}: {
  open: boolean;
  handleOpen: (value: boolean) => void;
  data: any;
  setData: (value: any) => void;
  onSubmit: (newOperatingLocation: any) => void | boolean;
  type: string;
  status: ActionType;
  setAddNewModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isTown = (value: string): value is 'town' => value === 'town';

  const handleClose = () => {
    handleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (status === ActionType.updateCity || status === ActionType.updateTown) {
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries((formData as any).entries());
      const name_en = formJson.name_en;
      const name_ar = formJson.name_ar;
      const latitude = formJson.latitude;
      const longitude = formJson.longitude;
      const delivery_fee = formJson.delivery_fee;

      const newOperatingLocation = {
        ...data,
        name_ar: name_ar,
        name_en: name_en,
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
        delivery_fee: parseFloat(delivery_fee),
      };
      const res = onSubmit(newOperatingLocation);

      if (!res) return;
      handleClose();
    } else {
      setAddNewModal(true);
      handleClose();
    }
  };

  return (
    <Modal
      title="Operating Location Info"
      open={open}
      onCancel={handleClose}
      footer={null} // Custom footer, managed through form submission
    >
      <form onSubmit={handleSubmit}>
        <p>Please add all the data with careful inspection of each.</p>
        <div>
          <Input
            className="mb-3"
            name="name_en"
            label="Name in English"
            placeholder="Enter name in English"
            isRequired={true}
            value={data?.name_en}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData({ ...data, name_en: e.target.value });
            }}
            aria-label="Name in English"
            variant="bordered"
            fullWidth
          />
        </div>
        <Input
          className="mb-3"
          name="name_ar"
          label="Name in Arabic"
          placeholder="Enter name in Arabic"
          isRequired={true}
          value={data?.name_ar}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData({ ...data, name_ar: e.target.value });
          }}
          aria-label="Name in Arabic"
          variant="bordered"
          fullWidth
        />

        {type === 'city' && (
          <>
            <Input
              className="mb-3"
              name="delivery_fee"
              label="Delivery Fee"
              placeholder="Enter delivery fee"
              type="number"
              isRequired={true}
              value={data?.delivery_fee}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, delivery_fee: e.target.value });
              }}
              aria-label="Delivery Fee"
              variant="bordered"
              fullWidth
              disabled={isTown(type)}
            />

            <Input
              className="mb-3"
              name="cross_order_delivery_fee_discount"
              label="Cross Order Delivery Fee Discount"
              placeholder="Enter fee discount"
              type="number"
              isRequired={true}
              value={data?.cross_order_delivery_fee_discount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData({
                  ...data,
                  cross_order_delivery_fee_discount: e.target.value,
                });
              }}
              aria-label="Cross Order Delivery Fee Discount"
              variant="bordered"
              fullWidth
              disabled={isTown(type)}
            />
          </>
        )}
        <Input
          className="mb-3"
          name="longitude"
          label="Center Longitude"
          placeholder="Enter center longitude"
          isRequired={true}
          value={data?.lng}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData({ ...data, lng: e.target.value });
          }}
          aria-label="Center Longitude"
          variant="bordered"
          fullWidth
        />

        <Input
          className="mb-3"
          name="latitude"
          label="Center Latitude"
          placeholder="Enter center latitude"
          isRequired={true}
          value={data?.lat}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData({ ...data, lat: e.target.value });
          }}
          aria-label="Center Latitude"
          variant="bordered"
          fullWidth
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        >
          <Button onPress={handleClose} style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button variant="solid" type="submit" color="success">
            {status}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
