import { Link, useOutletContext } from 'react-router-dom';
import {
  OrderDetails,
  OrderDocumentTypeName,
} from 'silal_app_base_react/src/data/types/orders';
import { ScreenWrapper } from '../../screens_wrapper';
import { useState } from 'react';
import {
  Checkbox,
  CheckboxOptionType,
  Divider,
  Row,
  Table,
  TableColumnsType,
} from 'antd';
import {
  OrderLedgerTransaction,
  TRANSACTION_METHODS_NAME_MAP,
  TRANSACTION_STATUS_NAME_MAP,
  TRANSACTION_TYPES_NAME_MAP,
} from 'silal_app_base_react/src/data/types/transactions';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { getTransactioTypeCashBalanceColor } from 'silal_app_base_react/src/pages/ledger/functions/ledger_functions';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { OrderItemsTable } from '../../components/order_items_table';
import { OrderPaymentInfoRow } from '../../components/order_payment_info_row';
import { IoReceiptOutline } from 'react-icons/io5';
import { Colors } from 'silal_app_base_react/src/config/theme';
import TransactionsRepository from 'data/repositories/transactions_repository';
import { toast } from 'react-toastify';
import { RiCoupon2Line } from 'react-icons/ri';
import CouponCard from 'pages/promotions/components/coupon_card';

function OrderLedgerTab() {
  const { orderDetails } = useOutletContext<{
    orderDetails: OrderDetails;
    showManagementNote: boolean;
    setShowManagementNote: (value: boolean) => void;
  }>();

  const [refunding, setRefunding] = useState(false);

  function cellNumberRenderer(value: string, row: OrderLedgerTransaction) {
    return (
      <p
        style={defaultTextStyle}
        className={getTransactioTypeCashBalanceColor(row.transaction_type)}
      >
        {' '}
        {formatPrice(value)}
      </p>
    );
  }

  const payLightColor = 'bg-green-100';
  const payDarkColor = 'bg-green-200';
  const refundLightColor = 'bg-orange-100';
  const refundDarkColor = 'bg-orange-200';
  const debtLightColor = 'bg-red-100';
  const debtDarkColor = 'bg-red-200';
  const defaultTextStyle = {
    color: Colors.greenAlternate,
    fontSize: 14,
  };
  const columns: TableColumnsType<OrderLedgerTransaction> = [
    {
      title: `ID-Created-Completed`,
      dataIndex: 'transaction_id',
      key: '0',
      render: (value: string, row: OrderLedgerTransaction) => {
        return (
          <p style={defaultTextStyle}>
            #{value} -{' '}
            {formateServerDateToLocaleDate(row.transaction_creation_date)} -{' '}
            {formateServerDateToLocaleDate(row.transaction_completion_date)}
          </p>
        );
      },
    },
    {
      title: `Type`,
      dataIndex: 'transaction_type',
      key: '11',
      render: (_value: string, row: OrderLedgerTransaction) => {
        return (
          <p style={defaultTextStyle}>
            {TRANSACTION_TYPES_NAME_MAP[row.transaction_type]}
          </p>
        );
      },
    },
    {
      title: `Amount`,
      dataIndex: 'transaction_amount',
      key: '12',
      render: (_value: string, row: OrderLedgerTransaction) => {
        return (
          <p
            style={defaultTextStyle}
            className={getTransactioTypeCashBalanceColor(row.transaction_type)}
          >
            {formatPrice(row.transaction_amount)}
          </p>
        );
      },
    },
    {
      title: `Method`,
      dataIndex: 'transaction_method',
      key: '13',
      render: (_value: string, row: OrderLedgerTransaction) => {
        return (
          <p style={defaultTextStyle}>
            {TRANSACTION_METHODS_NAME_MAP[row.transaction_method]}
          </p>
        );
      },
    },
    {
      title: `Status`,
      dataIndex: 'transaction_status',
      key: '14',
      render: (_value: string, row: OrderLedgerTransaction) => {
        return (
          <p style={defaultTextStyle}>
            {TRANSACTION_STATUS_NAME_MAP[row.transaction_status]}
          </p>
        );
      },
    },
    {
      title: 'Paid',
      children: [
        {
          title: `Cash: ${formatPrice(orderDetails.order_ledger.amount_paid_by_customer_in_cash.toString())}`,
          dataIndex: 'amount_paid_by_customer_in_cash',
          key: '1',
          render: cellNumberRenderer,
          className: payLightColor,
        },
        {
          title: `Card: ${formatPrice(orderDetails.order_ledger.amount_paid_by_customer_in_credit_card.toString())}`,
          dataIndex: 'amount_paid_by_customer_in_credit_card',
          key: '2',
          render: cellNumberRenderer,
          className: payLightColor,
        },
        {
          title: `Credit: ${formatPrice(orderDetails.order_ledger.amount_paid_by_customer_in_credit_balance.toString())}`,
          dataIndex: 'amount_paid_by_customer_in_credit_balance',
          key: '3',
          render: cellNumberRenderer,
          className: payLightColor,
        },
        {
          title: `Paid: ${formatPrice(orderDetails.order_ledger.amount_paid_by_customer.toString())}`,
          dataIndex: 'amount_paid_by_customer',
          key: '4',
          render: cellNumberRenderer,
          className: payDarkColor,
        },
      ],
    },
    {
      title: 'Refunded',
      children: [
        {
          title: `Card: ${formatPrice(orderDetails.order_ledger.amount_refunded_in_credit_card.toString())}`,
          dataIndex: 'amount_refunded_in_credit_card',
          key: '5',
          render: cellNumberRenderer,
          className: refundLightColor,
        },
        {
          title: `Credit: ${formatPrice(orderDetails.order_ledger.amount_refunded_in_credit_balance.toString())}`,
          dataIndex: 'amount_refunded_in_credit_balance',
          key: '6',
          render: cellNumberRenderer,
          className: refundLightColor,
        },
        {
          title: `Refunded: ${formatPrice(orderDetails.order_ledger.amount_refunded.toString())}`,
          dataIndex: 'amount_refunded',
          key: '7',
          render: cellNumberRenderer,
          className: refundDarkColor,
        },
      ],
    },
    {
      title: 'Debt',
      children: [
        {
          title: `Pending to Store: ${formatPrice(orderDetails.order_ledger.pending_debt_to_store_balance.toString())}`,
          dataIndex: 'pending_debt_to_store',
          key: '9',
          render: cellNumberRenderer,
          className: debtLightColor,
        },
        {
          title: `Deducted from Store: ${formatPrice(orderDetails.order_ledger.amount_deducted_from_store_debt.toString())}`,
          dataIndex: 'amount_deducted_from_store_debt',
          key: '10',
          render: cellNumberRenderer,
          className: debtLightColor,
        },
        {
          title: `Debt to Store Pre Deductions: ${formatPrice(
            orderDetails.order_ledger?.debt_to_store_pre_deductions?.toString(),
          )}`,
          dataIndex: 'debt_to_store_pre_deductions',
          key: '8',
          render: cellNumberRenderer,
          className: debtDarkColor,
        },
        {
          title: `Net Debt to Store: ${formatPrice(
            orderDetails.order_ledger?.net_debt_to_store?.toString(),
          )}`,
          dataIndex: 'net_debt_to_store',
          key: '9',
          render: cellNumberRenderer,
          className: debtDarkColor,
        },
      ],
    },
    {
      title: `Additional Context`,
      dataIndex: 'transaction_additional_context',
      key: '15',
      render: (_value: string, row: OrderLedgerTransaction) => {
        return (
          <p style={defaultTextStyle}>
            {JSON.stringify(row.transaction_additional_context)}
          </p>
        );
      },
    },
  ];
  const [checkedGeneral, setCheckedGeneral] = useState(['0', '11', '12']);
  const [checkedPay, setCheckedPay] = useState(['4']);
  const [checkedRefund, setCheckedRefund] = useState(['7']);
  const [checkedDebt, setCheckedDebt] = useState(['10']);
  const general = [
    {
      label: 'Transaction ID',
      value: '0',
    },
    {
      label: 'Transaction Type',
      value: '11',
    },
    {
      label: 'Transaction Amount',
      value: '12',
    },
    {
      label: 'Transaction Method',
      value: '13',
    },
    {
      label: 'Transaction Status',
      value: '14',
    },
    {
      label: 'Additional Context',
      value: '15',
    },
  ];
  const pay = [
    {
      label: 'Cash',
      value: '1',
    },
    {
      label: 'Card',
      value: '2',
    },
    {
      label: 'Credit',
      value: '3',
    },
    {
      label: 'Total Paid',
      value: '4',
    },
  ];
  const refund = [
    {
      label: 'Card',
      value: '5',
    },
    {
      label: 'Credit',
      value: '6',
    },
    {
      label: 'Total Refunded',
      value: '7',
    },
  ];
  const debt = [
    {
      label: 'Pending Debt to Store',
      value: '9',
    },
    {
      label: 'Deducted from Store Debt',
      value: '10',
    },
    {
      label: 'Debt to Store',
      value: '8',
    },
  ];

  const newColumns = columns.map((item) => {
    // check if chilren doesnt have the key
    if ((item as any).children) {
      const children = (item as any).children.map((child: any) => {
        return {
          ...child,
          hidden:
            !checkedGeneral.includes(child.key as string) &&
            !checkedPay.includes(child.key as string) &&
            !checkedRefund.includes(child.key as string) &&
            !checkedDebt.includes(child.key as string),
        };
      });
      return {
        ...item,
        children,
      };
    }
    return {
      ...item,
      hidden:
        !checkedGeneral.includes(item.key as string) &&
        !checkedPay.includes(item.key as string) &&
        !checkedRefund.includes(item.key as string) &&
        !checkedDebt.includes(item.key as string),
    };
  });
  return (
    orderDetails && (
      <ScreenWrapper
        style={{
          height: '100%',
        }}
      >
        <>
          <Row>
            <p
              style={{
                marginRight: 90,
                fontWeight: 'bold',
              }}
            >
              General:
            </p>
            <Checkbox.Group
              value={checkedGeneral}
              options={general as CheckboxOptionType[]}
              onChange={(values) => setCheckedGeneral(values as string[])}
            />
          </Row>
          <br />{' '}
          <Row>
            <p
              style={{
                marginRight: 25,
                fontWeight: 'bold',
              }}
            >
              Payment Related:
            </p>
            <Checkbox.Group
              value={checkedPay}
              options={pay as CheckboxOptionType[]}
              onChange={(values) => setCheckedPay(values as string[])}
            />
          </Row>
          <br />{' '}
          <Row>
            <p
              style={{
                marginRight: 37,
                fontWeight: 'bold',
              }}
            >
              Refund Related:
            </p>
            <Checkbox.Group
              value={checkedRefund}
              options={refund as CheckboxOptionType[]}
              onChange={(values) => setCheckedRefund(values as string[])}
            />
          </Row>
          <br />
          <Row>
            <p
              style={{
                marginRight: 54,
                fontWeight: 'bold',
              }}
            >
              Debt Related:
            </p>
            <Checkbox.Group
              value={checkedDebt}
              options={debt as CheckboxOptionType[]}
              onChange={(values) => setCheckedDebt(values as string[])}
            />
          </Row>
          <Divider />
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                marginRight: 10,
                fontWeight: 'bold',
              }}
            >
              Order Documents:
            </p>
            {orderDetails.order_documents?.[0].map((doc, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 10,
                  }}
                  key={index}
                >
                  <IoReceiptOutline
                    size={25}
                    style={{
                      cursor: 'pointer',
                      color: Colors.greenMain,
                      marginRight: 5,
                    }}
                    onClick={async () => {
                      await TransactionsRepository.getOrderDocumentUrl(
                        doc.document_id.toString(),
                        doc.document_type_name,
                      ).then((res) => {
                        if (!res) toast.warn('Failed to get document URL');
                        // Open link
                        window.open(res, '_blank');
                      });
                    }}
                  />
                  <div
                    style={{
                      fontSize: 16,
                      color: Colors.greenMain,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        color: Colors.primaryText,
                      }}
                    >
                      {' '}
                      {doc.document_type_name}
                    </p>
                    <h6
                      style={{
                        fontSize: 12,
                        color: Colors.secondaryText,
                      }}
                    >
                      {' '}
                      {formateServerDateToLocaleDate(doc.date_created)}
                    </h6>
                  </div>
                </div>
              );
            })}
          </Row>
          <Divider />
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                marginRight: 10,
                fontWeight: 'bold',
              }}
            >
              Order Discounts:
            </p>
            {orderDetails.discount_lines?.map((discount, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 10,
                  }}
                  key={index}
                >
                  <RiCoupon2Line
                    size={25}
                    style={{
                      cursor: 'pointer',
                      color: Colors.greenMain,
                      marginRight: 5,
                    }}
                    onClick={async () => {}}
                  />
                  <div
                    style={{
                      fontSize: 16,
                      color: Colors.greenMain,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Link to={`/promotions`} target="_blank">
                      <p
                        style={{
                          fontSize: 14,
                          color: Colors.primaryText,
                        }}
                      >
                        <span
                          style={{
                            color: Colors.greenMain,
                          }}
                        >
                          {' '}
                          #{discount.id}
                        </span>{' '}
                        {discount.name_en + ' - ' + discount.name_ar}
                      </p>
                    </Link>
                    <h6
                      style={{
                        fontSize: 12,
                        color: Colors.secondaryText,
                      }}
                    >
                      Amount : {formatPrice(discount.amount)} / Store:{' '}
                      {formatPrice(discount.amount_deducted_from_store)}
                    </h6>
                  </div>
                </div>
              );
            })}
          </Row>
          <Divider />
          <OrderPaymentInfoRow orderDetails={orderDetails} />
          <Table
            columns={newColumns}
            dataSource={orderDetails.order_ledger.transactions_json}
            style={{ marginTop: 24 }}
            scroll={{ x: true }}
          />
        </>{' '}
        <Divider />
        <OrderItemsTable
          refunding={refunding}
          setRefunding={setRefunding}
          orderDetails={orderDetails}
        />
      </ScreenWrapper>
    )
  );
}

export default OrderLedgerTab;
