import { Outlet, useParams } from 'react-router-dom';
import CustomerProfileToprow from '../components/top_row';
import { useEffect, useState } from 'react';
import Spinner from 'silal_app_base_react/src/components/spinner';
import UsersRepository from 'data/repositories/users_repository';
import { CustomerUserInfo } from 'silal_app_base_react/src/data/types/users';

export default function CustomerProfileParentRouteComponent() {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<CustomerUserInfo>();

  useEffect(() => {
    async function fetchCustomer() {
      await UsersRepository.getCustomerDetails(parseInt(customerId!)).then(
        (res) => {
          if (res) setCustomer(res as CustomerUserInfo);
        },
      );
    }
    fetchCustomer();
  }, [customerId]);

  return (
    <div>
      {customer ? (
        <div>
          <CustomerProfileToprow customer={customer} />
          <Outlet context={customer} />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
